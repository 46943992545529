import React from "react";
import {
    IonButton, IonCard,
    IonCardContent,
    IonCardHeader, IonCardTitle,
    IonDatetime,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonRadio,
    IonRadioGroup,
    IonRange
} from "@ionic/react";
import {Modal} from "../../components/modal/Modal";
import {LocationSelectModal} from "../../components/modal/LocationSelectModal";
import {ShortSpot} from "../../data/repository/Spot";
import {useOnlineState} from "../../data/Helpers";
import {addOutline} from "ionicons/icons";
import {LocationCreateModal} from "../../components/modal/LocationCreateModal";
import {IonItemWithValidation} from "../../components/ValidationWarning";
import {Exposition} from "../../data/ConditionsReport";
import {ExpositionSelectorMultiple} from "../../components/ExpositionSelector";
import {StepScope, StepValidationResult, ValidationScope} from "../../data/ConditionsReportEditing";
import {useI18n} from "../../i18n/i18n";

const ALTITUDE_MIN = 0;
const ALTITUDE_MAX = 4800;

export interface Step1Data {
    location: {
        uuid: string,
        name: string,
    },
    date: string,
    altitude: {
        upper: number,
        lower: number,
    },
    exposition: Exposition[],
    kind: 'Skitour' | 'Freeride' | 'Sonstiges'
}

export function Step1EmptyData(): Step1Data {
    return {
        location: {
            uuid: '',
            name: '',
        },
        date: (new Date()).toISOString().substring(0, 10),
        altitude: {
            lower: ALTITUDE_MIN,
            upper: ALTITUDE_MAX,
        },
        exposition: [],
        kind: 'Skitour'
    };
}

export function validateStep1({data, label}: ValidationScope<Step1Data>): StepValidationResult<Step1Data> {
    const validation: StepValidationResult<Step1Data> = {}

    if (data.exposition.length === 0) {
        validation.exposition = [ label('cr.exposition.validation.exists') ]
    }
    if (data.location.uuid === '') {
        validation.location = [ label('cr.location.validation.exists') ]
    }
    if (!data.date) {
        validation.date = [ label('cr.date.validation.exists') ]
    }
    if (data.altitude.lower === ALTITUDE_MIN && data.altitude.upper === ALTITUDE_MAX) {
        validation.altitude = [ label('cr.altitude.validation.exists') ]
    }

    return validation;
}

export const Step1Form: React.FunctionComponent<StepScope<Step1Data>> = (props) => {
    const { label } = useI18n();
    const {data, validation, updateData} = props;

    const [showLocationSelector, setShowLocationSelector] = React.useState(false)
    const [showLocationCreator, setShowLocationCreator] = React.useState(false)
    const [online] = useOnlineState();
    const [ today ] = React.useState<Date>(new Date());

    const selectLocation = (spot: ShortSpot | null) => {
        if (spot) {
            updateData({location: {uuid: spot.uuid, name: spot.title}})
        } else {
            updateData({location: {uuid: '', name: ''}})
        }
        setShowLocationSelector(false);
        setShowLocationCreator(false);
    }

    return <React.Fragment>
        <IonList>
            <IonItemWithValidation errors={validation.location}>
                <IonButton slot="start" color="primary" onClick={() => setShowLocationSelector(true)}>
                    {label('cr.location.select')}*
                </IonButton>
                <div>
                    <IonInput
                      placeholder={label('cr.location') + '...'}
                      value={data.location.name}
                      readonly
                      onClick={() => setShowLocationSelector(true)}
                    />
                </div>
            </IonItemWithValidation>
            <IonItem lines="none">
                <IonLabel>{label('cr.location.select_or_add')}</IonLabel>
            </IonItem>
            <IonItem>
                <IonButton slot="start" color="primary" onClick={() => setShowLocationCreator(true)} disabled={!online}>
                    <IonIcon icon={addOutline} slot="start"/>
                    {label('cr.location.add')}
                </IonButton>
            </IonItem>

            <IonItemWithValidation errors={validation.date}>
                <IonLabel>{label('cr.date')}*</IonLabel>
                <IonDatetime
                    value={data.date}
                    onIonChange={(e: any) => updateData({date: e.detail.value})}
                    locale="de-DE"
                    presentation="date"
                    max={today.toISOString().substring(0, 10)}
                />
            </IonItemWithValidation>

            <IonItemWithValidation errors={validation.altitude}>
                <IonRange
                    value={data.altitude}
                    onIonChange={(e: any) => updateData({
                        altitude: {
                            upper: e.detail.value.upper,
                            lower: e.detail.value.lower
                        }
                    })}
                    dualKnobs={true}
                    min={ALTITUDE_MIN}
                    max={ALTITUDE_MAX}
                    step={100}
                    pin={true}
                    labelPlacement="fixed"
                >
                    <div slot="label">
                        {label('cr.altitude')}*
                        <br/>
                        {data.altitude.lower}m <br/>
                        {data.altitude.upper}m
                    </div>
                </IonRange>
            </IonItemWithValidation>

            <IonItemWithValidation errors={validation.exposition}>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>{label('cr.exposition')}*</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <ExpositionSelectorMultiple
                            expositions={data.exposition}
                            onChange={exposition => updateData({exposition})}
                        />
                    </IonCardContent>
                </IonCard>
            </IonItemWithValidation>

            <IonItemWithValidation errors={validation.kind}>
                <IonList lines="full">
                    <IonRadioGroup value={data.kind} onIonChange={e => updateData({kind: e.detail.value})}>
                        <IonListHeader>{label('cr.kind')}*</IonListHeader>
                        <IonItem lines="none">
                            <IonRadio value={'Skitour'}>{label('cr.kind.skitour')}</IonRadio>
                        </IonItem>
                        <IonItem lines="none">
                            <IonRadio value={'Freeride'}>{label('cr.kind.freeride')}</IonRadio>
                        </IonItem>
                        <IonItem lines="none">
                            <IonRadio value={'Sonstiges'}>{label('cr.kind.other')}</IonRadio>
                        </IonItem>
                    </IonRadioGroup>
                </IonList>
            </IonItemWithValidation>
        </IonList>

        <Modal open={showLocationSelector} onClose={() => setShowLocationSelector(false)}>
            <LocationSelectModal
                location={data.location.uuid}
                onDismiss={() => setShowLocationSelector(false)}
                onSelect={(uuid, spot) => selectLocation(spot)}
            />
        </Modal>
        <Modal open={showLocationCreator} onClose={() => setShowLocationCreator(false)}>
            <LocationCreateModal onDismiss={() => setShowLocationCreator(false)}
                                 onCreate={spot => selectLocation(spot)}/>
        </Modal>
    </React.Fragment>
}