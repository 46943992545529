import {implementationChooser} from "../Helpers";
import {FileHandlingUrlCacheDecorator} from "./FileHandlingUrlCacheDecorator";
import {NativeFileHandlingBackend} from "./NativeFileHandlingBackend";
import {WebFileHandlingBackend} from "./WebFileHandlingBackend";
import {GenericTemporaryFileHandler} from "./GenericTemporaryFileHandler";

export interface FileOnDisk {
  fileName: string,
  size: number,
  sizeMb: number,
  nativePath: string,
  mimeType: string,
}

export interface TemporaryFileHandler {
  writeToDisk(fileName: string, data: Blob|string): Promise<string>
  clearStorage(): Promise<void>
}

export interface FileHandlingBackend {
  getFile(path: string): Promise<FileOnDisk>
  getPublicUrl(file: FileOnDisk): Promise<string>
  readFile(file: FileOnDisk): Promise<Blob>
  deleteFile(file: FileOnDisk): Promise<void>
}

export const temporaryFiles = new GenericTemporaryFileHandler('/tmp');
export const fileHandling = implementationChooser<FileHandlingBackend>({
  ios: () => new FileHandlingUrlCacheDecorator(new NativeFileHandlingBackend()),
  android: () => new FileHandlingUrlCacheDecorator(new NativeFileHandlingBackend()),
  web: () => new FileHandlingUrlCacheDecorator(new WebFileHandlingBackend()),
})