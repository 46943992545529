import {Step1Data, Step1EmptyData, Step1Form, validateStep1} from "../pages/CreationForm/Step1";
import {Step2Data, Step2EmptyData, Step2Form, validateStep2} from "../pages/CreationForm/Step2";
import {Step3Data, Step3EmptyData, Step3Form, validateStep3} from "../pages/CreationForm/Step3";
import {Step4Data, Step4EmptyData, Step4Form, validateStep4} from "../pages/CreationForm/Step4";
import React, {useEffect} from "react";
import {conditionsReportEditingStorage} from "./storage";
import {checkmarkDoneOutline, locationOutline, snowOutline, warningOutline} from "ionicons/icons";
import {useHistory, useParams} from "react-router";
import {StepFormWrapper} from "../pages/CreationForm/StepFormWrapper";
import {Submit} from "../pages/CreationForm/Submit";
import {Redirect, Route} from "react-router-dom";
import {ConditionsReportWithId} from "./repository/ConditionsReport";
import {LabelFunc, LanguageLabel, useI18n} from "../i18n/i18n";

export const ID_NEW = 'new'

export interface StepData {
    step1: Step1Data,
    step2: Step2Data,
    step3: Step3Data,
    step4: Step4Data,
}

export const stepNames: (keyof StepData)[] = ['step1', 'step2', 'step3', 'step4']


export const steps: ConditionsReportEditingSteps = {
    step1: {
        titleLabel: 'editing.step_1',
        icon: locationOutline,
        render: Step1Form,
        emptyData: Step1EmptyData,
        validate: validateStep1,
    },
    step2: {
        titleLabel: 'editing.step_2',
        icon: snowOutline,
        render: Step2Form,
        emptyData: Step2EmptyData,
        validate: validateStep2,
    },
    step3: {
        titleLabel: 'editing.step_3',
        icon: warningOutline,
        render: Step3Form,
        emptyData: Step3EmptyData,
        validate: validateStep3,
    },
    step4: {
        titleLabel: 'editing.step_4',
        icon: checkmarkDoneOutline,
        render: Step4Form,
        emptyData: Step4EmptyData,
        validate: validateStep4,
    }
}

export interface ConditionsReportEditingStepDefinition<TData> {
    titleLabel: LanguageLabel,
    icon: string,
    emptyData: () => TData,
    render: React.FunctionComponent<StepScope<TData>>,
    validate: (scope: ValidationScope<TData>) => StepValidationResult<TData>,
}

export type ConditionsReportEditingSteps = {
    [K in keyof StepData]: ConditionsReportEditingStepDefinition<StepData[K]>
}

export type StepValidationResult<T> = Partial<Record<keyof T, string[]>>

export interface StepScope<TData> {
    step: ConditionsReportEditingStepDefinition<TData>,
    data: TData,
    allData: StepData,
    validation: StepValidationResult<TData>,
    showValidation: boolean,
    updateData: (data: Partial<TData>) => Promise<void>,
}

export interface ValidationScope<TData> {
    step: ConditionsReportEditingStepDefinition<TData>,
    data: TData,
    allData: StepData,
    label: LabelFunc,
}

function emptyData(steps: ConditionsReportEditingSteps): StepData {
    const data: Partial<StepData> = {}
    for (const key of Object.keys(steps) as (keyof StepData)[]) {
        data[key] = steps[key].emptyData() as any
    }
    return data as StepData
}

function emptyValidation(steps: ConditionsReportEditingSteps): { [K in keyof StepData]: StepValidationResult<StepData[K]> } {
    const validation: Partial<{ [K in keyof StepData]: StepValidationResult<StepData[K]> }> = {}
    for (const key of Object.keys(steps) as (keyof StepData)[]) {
        validation[key] = {}
    }
    return validation as { [K in keyof StepData]: StepValidationResult<StepData[K]> }
}


interface ConditionsReportEditingContext {
    initialize: (cr: ConditionsReportWithId|null) => void,
    id: string,
    steps: ConditionsReportEditingSteps,
    stepData: StepData,
    validation: { [K in keyof StepData]: StepValidationResult<StepData[K]> },
    showValidation: boolean,
    updateStepData: <TStep extends keyof StepData>(step: TStep, data: Partial<StepData[TStep]>) => Promise<void>,
    deleteInProgressConditionsReport: () => Promise<void>,
    hasInProgressConditionsReport: boolean,
    goToStep: <TStep extends keyof StepData>(step: TStep) => void,
    goToPreview: () => void,
    goToFirstStepWithValidationErrors: () => boolean,
}

export const ConditionsReportEditingContext = React.createContext<ConditionsReportEditingContext | null>(null)
export const ConditionsReportEditingProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({children}) => {
    const history = useHistory()
    const [hasInProgressConditionsReport, setHasInProgressConditionsReport] = React.useState<boolean>(false)
    const [stepData, setStepData] = React.useState<StepData>(emptyData(steps))
    const [validation, setValidation] = React.useState<{ [K in keyof StepData]: StepValidationResult<StepData[K]> }>(emptyValidation(steps))
    const [showValidation, setShowValidation] = React.useState<boolean>(false)
    const [ id, setId ] = React.useState<string>(ID_NEW)
    const { label } = useI18n();

    // Load the current active steps from storage and merge them with the default data
    useEffect(() => {
        conditionsReportEditingStorage.loadStepData(id).then(activeSteps => {
            setHasInProgressConditionsReport(Object.keys(activeSteps).length > 0)
            const newStepData = {...stepData}
            for (const step of stepNames) {
                newStepData[step] = {...stepData[step], ...(activeSteps[step] ?? {})} as any
            }
            setStepData(newStepData)
        })
    }, [ id ])

    useEffect(() => {
        const newValidation = {...validation}
        for (const step of stepNames) {
            newValidation[step] = steps[step].validate({
                step: steps[step] as any,
                data: stepData[step] as any,
                allData: stepData,
                label: label,
            })
        }
        setValidation(newValidation);
    }, [stepData]);

    const initialize = (cr: ConditionsReportWithId|null) => {
        if (cr === null) {
            setId(ID_NEW)
            return;
        }

        throw new Error('Editing existing conditions reports is not yet implemented')
    }

    async function updateStepData<TStep extends keyof StepData>(step: TStep, data: Partial<StepData[TStep]>): Promise<void> {
        const newStepData: StepData = {
            ...stepData,
            [step]: {
                ...stepData[step],
                ...data,
            }
        }
        setStepData(newStepData)
        await conditionsReportEditingStorage.storeStepData(id, newStepData)
        setHasInProgressConditionsReport(true)
    }

    async function deleteInProgressConditionsReport(): Promise<void> {
        await conditionsReportEditingStorage.removeStepData(id)
        setStepData(emptyData(steps))
        setHasInProgressConditionsReport(false)
    }

    function goToStep<TStep extends keyof StepData>(step: TStep): void {
        history.push(`/edit/${id}/${step}`)
    }

    function goToFirstStepWithValidationErrors(): boolean {
        setShowValidation(true);
        for (const stepName of stepNames) {
            if (Object.keys(validation[stepName]).length > 0) {
                goToStep(stepName)
                return true;
            }
        }

        return false;
    }

    function goToPreview(): void {
        if (goToFirstStepWithValidationErrors()) {
            return;
        }

        history.push(`/edit/${id}/submit`)
    }

    return <ConditionsReportEditingContext.Provider value={{
        initialize,
        id,
        steps,
        stepData,
        validation,
        showValidation,
        updateStepData,
        deleteInProgressConditionsReport,
        hasInProgressConditionsReport,
        goToStep,
        goToPreview,
        goToFirstStepWithValidationErrors,
    }}>
        {children}
    </ConditionsReportEditingContext.Provider>
}

export function useInProgressConditionsReport() {
    return React.useContext(ConditionsReportEditingContext)!
}

export function getConditionsReportRoutes(): React.ReactElement[] {
    const routes = stepNames.map((stepName) => (
        <Route exact key={stepName} path={`/edit/:id/${stepName}`}>
            <StepFormWrapper stepName={stepName} step={steps[stepName]}/>
        </Route>
    ))

    routes.push(<Route exact path={`/edit/:id/submit`} key="submit"><Submit /></Route>)
    routes.push(<Route exact path={`/edit/:id`} key="redirect"><RedirectToFirstStep /></Route>)

    return routes;
}

const RedirectToFirstStep: React.FunctionComponent = () => {
    const params = useParams() as { id: string }

    return <Redirect to={`/edit/${params.id}/step1`} />
}