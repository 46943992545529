import {GenericApiClient} from "./GenericApiClient";
import {FileUploadAbsoluteOptions} from "./index";

export class WebApiClient extends GenericApiClient {
  async fileUploadAbsolute(options: FileUploadAbsoluteOptions): Promise<string> {
    const blob = await this.fileHandling.readFile(options.file);
    const formData = new FormData();
    formData.append(options.field, blob, options.file.fileName);
    for (const [key, value] of Object.entries(options.additionalFields ?? { })) {
      formData.append(key, value);
    }
    const response = await this.doRequest(
      options.url,
      options.method,
      options.headers ?? { },
      formData,
      true,
    );

    return await response.text();
  }
}