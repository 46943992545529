import {apiClient} from "../api";
import {TemporaryImage} from "../images";
import {TemporaryVideo} from "../videos";

interface VideoUploadConfiguration {
    url: string
    uid: string
}

interface Media {
    uuid: string,
    name: string,
    url: string,
}

class MediaRepository {
    async uploadPhoto(image: TemporaryImage, token: string): Promise<Media> {
        return await apiClient.fileUpload({
            token,
            endpoint: 'api/v2/media',
            method: 'POST',
            field: 'media',
            file: image,
        })
    }

    async uploadVideo(video: TemporaryVideo, token: string): Promise<string> {
        const upload = await this.getVideoUploadData(token);
        console.log(`Uploading video to ${upload.url} (will be ${upload.uid})`);

        await apiClient.fileUploadAbsolute({
            url: upload.url,
            method: 'POST',
            field: 'file',
            file: video,
        })

        return upload.uid;
    }

    private async getVideoUploadData(token: string): Promise<VideoUploadConfiguration> {
        const response = await apiClient.request({
            endpoint: 'api/v2/video/upload',
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });

        const body = await response.json();

        return {url: body.uploadUrl, uid: body.uid};
    }
}

export const mediaRepository = new MediaRepository();