import React from "react"
import {
    IonButton,
    IonContent, IonFooter,
    IonGrid,
    IonHeader,
    IonIcon, IonImg, IonPage,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import {arrowBackOutline} from "ionicons/icons";
import {environment} from "../environment";
import logo from '../assets/logo_bright.svg';
import {useHistory} from "react-router";
import { layoutData } from "../data/layout";

interface PageWrapperProps {
    title: string,
    children: React.ReactNode,
    showBackButton?: boolean,
    onBack?: () => void,
    additionalHeaderContent?: React.ReactNode,
    footer?: React.ReactNode,
}

export const PageWrapper: React.FunctionComponent<PageWrapperProps> = (props) => {
    const history = useHistory();

    const onBack = () => {
        if (props.onBack) {
            props.onBack()
        } else {
            history.goBack();
        }
    }

    return <IonPage>
        <IonHeader>
            <IonToolbar color="primary">
                <IonGrid fixed={true} style={{display: 'flex'}}>
                    {props.showBackButton &&
                        <IonButton shape="round" color="light" fill="outline" onClick={onBack}>
                            <IonIcon icon={arrowBackOutline}/>
                        </IonButton>}
                    {layoutData.showLogoInHeader && <IonButton fill="clear" href="/" style={{fontWeight: 'bold', height: '3rem', fontSize: '2rem', color: 'white'}}>
                        {environment.isProduction ? <IonImg src={logo} style={{ height: '100%' }} /> : '⚠ DEV'}
                    </IonButton>}
                    <IonTitle>{props.title}</IonTitle>
                </IonGrid>
            </IonToolbar>
            {props.additionalHeaderContent}
        </IonHeader>
        <IonContent>
            <IonGrid fixed={true}>
                {props.children}
            </IonGrid>
        </IonContent>
        {props.footer &&
            <IonFooter>
                <IonToolbar color="primary">
                    <IonGrid fixed={true}>
                        {props.footer}
                    </IonGrid>
                </IonToolbar>
            </IonFooter>
        }
    </IonPage>
}