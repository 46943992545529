import {Filesystem} from "@capacitor/filesystem";
import {FileHandlingBackend, FileOnDisk} from "./index";
import {base64Decode} from "./helpers";

export abstract class GenericFileHandlingBackend implements FileHandlingBackend {

  abstract getPublicUrl(file: FileOnDisk): Promise<string>;


  async getFile(path: string): Promise<FileOnDisk> {
    await Filesystem.requestPermissions();

    const stat = await Filesystem.stat({ path })
    console.log({ stat })

    const pathParts = path.split('/')
    const fileName = pathParts[pathParts.length - 1]

    return {
      fileName,
      size: stat.size,
      sizeMb: Math.round(stat.size / 1024 / 1024 * 100) / 100,
      nativePath: stat.uri,
      mimeType: stat.type,
    };
  }

  async readFile(file: FileOnDisk): Promise<Blob> {
    const result = await Filesystem.readFile({
      path: file.nativePath,
    })

    return base64Decode(result.data as string, file.mimeType);
  }

  async deleteFile(file: FileOnDisk): Promise<void> {
    await Filesystem.deleteFile({
      path: file.nativePath,
    })
  }
}
