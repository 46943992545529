import {PickedFile} from "@capawesome/capacitor-file-picker";
import {GenericVideoHandlingBackend} from "./GenericVideoHandlingBackend";
import {FileHandlingBackend, TemporaryFileHandler} from "../files";

export class WebVideoHandlingBackend extends GenericVideoHandlingBackend {
  constructor(
    fileHandling: FileHandlingBackend,
    private readonly temporaryFiles: TemporaryFileHandler
  ) {
    super(fileHandling);
  }

  async canLoadVideosFromCamera(): Promise<boolean> {
    return false;
  }
  async canLoadVideosFromGallery(): Promise<boolean> {
    return true;
  }

  // Web: File is not written to disk, but returned as a blob.
  //      We need to write the blob to disk ourselves.
  protected async extractPathsFromGalleryFile(file: PickedFile): Promise<{ pathOnDisk: string, publicPath: string }> {
    const pathOnDisk = await this.temporaryFiles.writeToDisk(file.name, file.blob!);
    const publicPath = URL.createObjectURL(file.blob!);

    return { pathOnDisk, publicPath };
  }

  protected async createVideoThumbnail(path: string, videoElement: HTMLVideoElement): Promise<string> {
    const fileName = path.split('/').pop()!;
    const blob = await this.createThumbnailFromVideoElement(videoElement);
    return this.temporaryFiles.writeToDisk(`${fileName}.thumbnail.jpg`, blob);
  }

  private createThumbnailFromVideoElement(videoElement: HTMLVideoElement): Promise<Blob> {
    return new Promise(resolve => {
      let retry = 5;
      let takingThumbnail = false;

      const timeupdate = async () => {
        if (takingThumbnail) {
          return;
        }

        takingThumbnail = true;
        const success = await attemptCreatingThumbnail();
        takingThumbnail = false;
        if (success) {
          return;
        }

        if (retry === 0) {
          throw 'Failed to create thumbnail';
        }

        retry--;
      }

      const attemptCreatingThumbnail = async () => {
        const thumbnail = await this.createVideoSnapshot(videoElement);
        if (!thumbnail) {
          return false;
        }
        videoElement.removeEventListener('timeupdate', timeupdate);
        videoElement.pause();
        resolve(thumbnail);

        return true;
      }

      videoElement.addEventListener('loadeddata', () => attemptCreatingThumbnail());
      videoElement.addEventListener('timeupdate', timeupdate);
      videoElement.play();
    })
  }

  private createVideoSnapshot(video: HTMLVideoElement): Promise<Blob | null> {
    video.width = video.videoWidth > 1000 ? video.videoWidth / 10 : video.videoWidth;
    video.height = video.videoWidth > 1000 ? video.videoHeight / 10 : video.videoHeight;
    const canvas = document.createElement('canvas');
    canvas.width = video.width;
    canvas.height = video.height;
    canvas.getContext('2d')!.drawImage(video, 0, 0, video.width, video.height);

    return new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg', 0.85));
  }
}