import {Storage} from "@ionic/storage";
import {StepData} from "./ConditionsReportEditing";

class ConditionsReportEditingStorage {

    private storage: Storage;

    constructor() {
        this.storage = new Storage();
        this.storage.create();
    }

    private storageKey(id: string): string {
        return `conditions_report_edit.${id}`
    }

    async loadStepData(id: string): Promise<Partial<StepData>> {
        const key = this.storageKey(id)
        const result = await this.storage.get(key) as Partial<StepData> | null

        return result ?? { }
    }

    async storeStepData(id: string, data: StepData): Promise<void> {
        const key = this.storageKey(id)
        await this.storage.set(key, data)
    }

    async removeStepData(id: string): Promise<void> {
        const key = this.storageKey(id)
        await this.storage.remove(key)
    }

    async hasStepData(id: string): Promise<boolean> {
        const key = this.storageKey(id)
        const result = await this.storage.get(key) as Partial<StepData> | null

        return result !== null;
    }
}

export const conditionsReportEditingStorage = new ConditionsReportEditingStorage();