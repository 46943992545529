import {Storage} from '@ionic/storage';

class CacheStorage {

    private cache: Record<string, any> = {};
    private storage: Storage;

    constructor() {
        this.storage = new Storage();
        this.storage.create().then(() => this.load());
    }

    get<T>(key: string): T | null {
        return this.cache[key] ?? null;
    }

    public set<T>(key: string, value: T): void {
        this.cache[key] = value;
        this.persist()
    }

    private persist(): void {
        localStorage.setItem('cache', JSON.stringify(this.cache));
    }

    private load(): void {
        const cache = localStorage.getItem('cache');
        if (cache) {
            this.cache = JSON.parse(cache);
        }
    }
}

export const cacheStorage = new CacheStorage();