import {apiClient} from "../api";
import {NativeApiClient} from "../api/NativeApiClient";
import {WebApiClient} from "../api/WebApiClient";

export interface User {
  id: number;
  username: string;
}

export enum AuthStatus {
  AUTHENTICATED,
  UNAUTHENTICATED,
  UNKNOWN,
}

class UserRepository {

  public async login(email: string, password: string, deviceId: string): Promise<string | null> {
    const result = await apiClient.request({
      endpoint: 'api/v2/authentication',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        username: email,
        password: password,
        device_id: deviceId,
      },
      throwOnFailure: false,
    })

    if (result.status === 422) {
      return null;
    }

    if (result.status >= 400) {
      throw new Error('Login fehlgeschlagen');
    }


    const body = await result.json() as { token: string };
    return body.token;
  }

  public async getUserData(token: string): Promise<[AuthStatus.AUTHENTICATED, User] | [AuthStatus, null]> {
    console.log({
      apiClient,
      web: apiClient instanceof WebApiClient,
      native: apiClient instanceof NativeApiClient,
    })
    const response = await apiClient.request({
      endpoint: 'api/v2/authentication',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      throwOnFailure: false,
    })

    if (response.status === 401) {
      return [AuthStatus.UNAUTHENTICATED, null];
    }

    if (response.status === 200) {
      const body = await response.json();
      return [AuthStatus.AUTHENTICATED, body.user as User];
    }

    return [AuthStatus.UNKNOWN, null];
  }
}

export const userRepository = new UserRepository();