import {ConditionsReportWithTemporaryMedia} from "../pages/CreationForm/conversion";
import {ConditionsReport} from "./ConditionsReport";
import {ObservableTaskCollection, Task} from "./Task";
import {mediaRepository} from "./repository/Media";
import {conditionsReportRepository, ConditionsReportWithId} from "./repository/ConditionsReport";
import {ApiError} from "./api";
import {AuthStatus, userRepository} from "./repository/User";
import {LabelFunc} from "../i18n/i18n";

export interface SubmissionError {
    error: any,
    userFacingMessage: string,
    propertyErrors: string[],
}


export function submitConditionsReport(
  cr: ConditionsReportWithTemporaryMedia,
  token: string,
  label: LabelFunc,
): [ObservableTaskCollection, () => Promise<[ConditionsReportWithId, null] | [null, SubmissionError]>] {

    const submissionCr: ConditionsReport = {
        ...cr.conditionsReport,
        media: [],
        video: null,
    }

    const checkIfUserSignedIn = new Task(label('submit.step.check_user'), async () => {
        const [ status ] = await userRepository.getUserData(token);
        if (status !== AuthStatus.AUTHENTICATED) {
            throw new Error(label('submit.error.not_signed_in_or_no_internet'));
        }
    });

    const mediaSubmission: Task<string>[] = cr.temporaryImages.map(media => new Task(label('submit.step.upload_image', { fileName: media.fileName }), async () => {
        try {
            const uploaded = await mediaRepository.uploadPhoto(media, token);
            return uploaded.uuid;
        } catch (e: any) {
            console.error('Error while uploading photo', e);
            throw new Error(label('submit.error.image_upload', { fileName: media.fileName, message: e.message ?? e.body ?? e }));
        }
    }));

    const videoSubmission: Task<string>[] = cr.temporaryVideos.map(video => new Task(label('submit.step.upload_video', { fileName: video.fileName }), async () => {
        try {
            return await mediaRepository.uploadVideo(video, token);
        } catch (e: any) {
            console.error('Error while uploading video', e);
            throw new Error(label('submit.error.video_upload', { fileName: video.fileName, message: e.message ?? e.body ?? e }));
        }
    }))

    const submitCrTask: Task<ConditionsReportWithId> = new Task(label('submit.step.submit_cr'), async () => {
        return conditionsReportRepository.submit(submissionCr, token);
    });

    const start = async (): Promise<[ConditionsReportWithId, null] | [null, SubmissionError]> => {
        try {
            await checkIfUserSignedIn.start();
            const videoIds = await Promise.all(videoSubmission.map(task => task.start()));
            const mediaIds = await Promise.all(mediaSubmission.map(task => task.start()));

            submissionCr.media = mediaIds;
            submissionCr.video = videoIds.join(',');

            return [await submitCrTask.start(), null];
        } catch (e: any) {
            console.error(e)
            if (e instanceof ApiError) {
                const response = await e.body;
                const errors: string[] = [];
                for (const propertyErrors of Object.values(response.errors ?? {}) as string[][]) {
                    errors.push(...propertyErrors);
                }
                const validationErrors = e.validationErrors();
                if (validationErrors) {
                    for (const propertyErrors of Object.values(validationErrors)) {
                        errors.push(...propertyErrors);
                    }
                }

                return [
                    null,
                    {
                        userFacingMessage: label('submit.error.generic'),
                        error: e,
                        propertyErrors: errors,
                    }
                ]
            } else {
                return [
                    null,
                    {
                        userFacingMessage: label('submit.error.unknown'),
                        error: e,
                        propertyErrors: [],
                    }
                ]
            }
        }
    }

    return [
        new ObservableTaskCollection<any>([checkIfUserSignedIn, ...mediaSubmission, ...videoSubmission, submitCrTask]),
        start,
    ]
}