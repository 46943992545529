// TODO: Activities
import {JSONSchemaType} from "./JsonSchema";

type Activity = string;

type Uuid = string;

export const UuidSchema: JSONSchemaType<Uuid> = {
    type: 'string',
    format: 'uuid',
}

export enum Exposition {
    North = 0,
    NorthEast = 1,
    East = 2,
    SouthEast = 3,
    South = 4,
    SouthWest = 5,
    West = 6,
    NorthWest = 7,
}

export const ExpositionSchema: JSONSchemaType<Exposition> = {
    type: 'integer',
    enum: [0, 1, 2, 3, 4, 5, 6, 7],
}

export enum AvalancheWarningLevel {
    Low = 1,
    Moderate = 2,
    Considerable = 3,
    High = 4,
    VeryHigh = 5,
}

export const AvalancheWarningLevelSchema: JSONSchemaType<AvalancheWarningLevel> = {
    type: 'integer',
    enum: [1, 2, 3, 4, 5],
}

export interface Review<TAttrs> {
    rating: number,
    description: string,
    attributes: TAttrs,
}

export type SnowQualityReview = Review<{
    old_snow: boolean,
    breakable_crust: boolean,
    slush: boolean,
    firn: boolean,
    wet_snow: boolean,
    powder: boolean,
    summer_snow: boolean,
    jelly_snow: boolean,
    wind_drifted: boolean,
    wind_drifted_attrs?: {
        exposition: Exposition[],
        altitude_upper?: number,
        altitude_lower?: number,
        intensity: number,
        description: string,
    }
}>

export const SnowQualityReviewSchema: JSONSchemaType<SnowQualityReview> = {
    type: 'object',
    properties: {
        rating: {type: 'number'},
        description: {type: 'string'},
        attributes: {
            type: 'object',
            properties: {
                old_snow: {type: 'boolean'},
                breakable_crust: {type: 'boolean'},
                slush: {type: 'boolean'},
                firn: {type: 'boolean'},
                wet_snow: {type: 'boolean'},
                powder: {type: 'boolean'},
                summer_snow: {type: 'boolean'},
                jelly_snow: {type: 'boolean'},
                wind_drifted: {type: 'boolean'},
                wind_drifted_attrs: {
                    type: 'object',
                    properties: {
                        exposition: {type: 'array', items: ExpositionSchema},
                        altitude_upper: {type: 'number'},
                        altitude_lower: {type: 'number'},
                        intensity: {type: 'number'},
                        description: {type: 'string'},
                    },
                    required: ['exposition', 'intensity', 'description'],
                },
            },
            required: ['old_snow', 'breakable_crust', 'slush', 'firn', 'wet_snow', 'powder', 'summer_snow', 'jelly_snow', 'wind_drifted'],
        },
    },
    required: ['rating', 'description', 'attributes'],
}

export type SnowConditionReview = Review<{
    descent_to_valley: boolean,
    descent_to_height: number,
    descent_through_forest: boolean,
    blown_backs: boolean,
    covered_shoes: boolean,
    variations_of_snow_cover: boolean,
    stone_contact_avoidable: boolean,
    fresh_snow: number,
}>

export const SnowConditionReviewSchema: JSONSchemaType<SnowConditionReview> = {
    type: 'object',
    properties: {
        rating: {type: 'number'},
        description: {type: 'string'},
        attributes: {
            type: 'object',
            properties: {
                descent_to_valley: {type: 'boolean'},
                descent_to_height: {type: 'number'},
                descent_through_forest: {type: 'boolean'},
                blown_backs: {type: 'boolean'},
                covered_shoes: {type: 'boolean'},
                variations_of_snow_cover: {type: 'boolean'},
                stone_contact_avoidable: {type: 'boolean'},
                fresh_snow: {type: 'number'},
            },
            required: ['descent_to_valley', 'descent_to_height', 'descent_through_forest', 'blown_backs', 'covered_shoes', 'variations_of_snow_cover', 'stone_contact_avoidable', 'fresh_snow'],
        },
    },
    required: ['rating', 'description', 'attributes'],
}

export type RiskReview = Review<{
    no_warning_signs: boolean,
    abort: boolean,
    cracking: boolean,
    fresh_avalanches: boolean,
    rockfall: boolean,
    crevices: boolean,
    diurnal_warming: boolean,
    much_fresh_snow: boolean,
    much_water: boolean,
    hissing_noise: boolean,
    old_snow_problem: boolean,
    wind_direction: Exposition[],
    wind_intensity: number,
    fresh_avalanches_attrs?: {
        exposition: Exposition,
        altitude: number,
        size: number,
        type: string,
        description: string,
    }
}>

export const RiskReviewSchema: JSONSchemaType<RiskReview> = {
    type: 'object',
    properties: {
        rating: {type: 'number'},
        description: {type: 'string'},
        attributes: {
            type: 'object',
            properties: {
                no_warning_signs: {type: 'boolean'},
                abort: {type: 'boolean'},
                cracking: {type: 'boolean'},
                fresh_avalanches: {type: 'boolean'},
                rockfall: {type: 'boolean'},
                crevices: {type: 'boolean'},
                diurnal_warming: {type: 'boolean'},
                much_fresh_snow: {type: 'boolean'},
                much_water: {type: 'boolean'},
                hissing_noise: {type: 'boolean'},
                old_snow_problem: {type: 'boolean'},
                wind_direction: {type: 'array', items: ExpositionSchema},
                wind_intensity: {type: 'number'},
                fresh_avalanches_attrs: {
                    type: 'object',
                    properties: {
                        exposition: ExpositionSchema,
                        altitude: {type: 'number'},
                        size: {type: 'number'},
                        type: {type: 'string'},
                        description: {type: 'string'},
                    },
                    required: ['exposition', 'altitude', 'size', 'type', 'description'],
                },
            },
            required: ['no_warning_signs', 'abort', 'cracking', 'fresh_avalanches', 'rockfall', 'crevices', 'diurnal_warming', 'much_fresh_snow', 'much_water', 'hissing_noise', 'old_snow_problem', 'wind_direction', 'wind_intensity'],
        },
    },
    required: ['rating', 'description', 'attributes'],
}

export type OverallReview = Review<{
    trend: number,
    index_of_first_image: number,
}>

export const OverallReviewSchema: JSONSchemaType<OverallReview> = {
    type: 'object',
    properties: {
        rating: {type: 'number'},
        description: {type: 'string'},
        attributes: {
            type: 'object',
            properties: {
                trend: {type: 'number'},
                index_of_first_image: {type: 'number'},
            },
            required: ['trend', 'index_of_first_image'],
        },
    },
    required: ['rating', 'description', 'attributes'],
}

type Url = string;

export const UrlSchema: JSONSchemaType<Url> = {
    type: 'string',
    format: 'url',
}

export interface ConditionsReport {
    title: string,
    activity_date: string,
    activity: Activity,
    media: string[],
    video: string|null,
    exposition: Exposition[],
    avalanche_warning_level: AvalancheWarningLevel,
    altitude_lower: number,
    altitude_upper: number,
    spot_id: Uuid,
    snowquality: SnowQualityReview,
    snowcondition: SnowConditionReview,
    risk: RiskReview,
    overall: OverallReview,
}

export const ConditionsReportSchema: JSONSchemaType<ConditionsReport> = {
    type: 'object',
    properties: {
        title: {type: 'string'},
        activity_date: {type: 'string'},
        activity: {type: 'string'},
        media: {type: 'array', items: {type: 'string'}},
        video: { type: 'string', nullable: true },
        exposition: {type: 'array', items: ExpositionSchema},
        avalanche_warning_level: AvalancheWarningLevelSchema,
        altitude_lower: {type: 'number'},
        altitude_upper: {type: 'number'},
        spot_id: UuidSchema,
        snowquality: SnowQualityReviewSchema,
        snowcondition: SnowConditionReviewSchema,
        risk: RiskReviewSchema,
        overall: OverallReviewSchema,
    },
    required: ['title', 'activity_date', 'activity', 'media', 'video', 'exposition', 'avalanche_warning_level', 'altitude_lower', 'altitude_upper', 'spot_id', 'snowquality', 'snowcondition', 'risk', 'overall'],
}