import React from "react";
import {Alert} from "@mui/material";
import {ApiError} from "../data/api";

interface ErrorProps {
  title?: string,
  error: any | null,
  children?: React.ReactNode,
}

export const ErrorBox: React.FunctionComponent<ErrorProps> = (props) => {
  const [ humanReadable, setHumanReadable ] = React.useState<string | null>(null);

  React.useEffect(() => {
    setHumanReadable(null);
    if (props.error) {
      console.error(props.error);
      setHumanReadable(errorToHumanReadable(props.error));
    }
  }, [ props.error ])

  const dismiss = () => setHumanReadable(null);

  if (humanReadable === null) {
    return null;
  }

  return <Alert severity="error" onClose={dismiss}>
    {props.title && <h3>{props.title}</h3>}
    <p>{humanReadable}</p>
    {props.children}
  </Alert>
}

function errorToHumanReadable(error: any): string {
  if (error instanceof ApiError && error.status === 422) {
    return 'Der Server hat die Anfrage nicht angenommen. Bitte überprüfen Sie Ihre Eingaben.';
  }

  if (error instanceof Error) {
    return `${error.name}: ${error.message}`;
  }

  if (typeof error === 'string') {
    return error;
  }

  return JSON.stringify(error, null, 4);
}