import {fileHandling, FileOnDisk} from "../data/files";
import React from "react";
import {IonButton, IonButtons, IonHeader, IonContent, IonIcon, IonImg, IonLoading, IonTitle, IonToolbar} from "@ionic/react";
import {StyleReactProps} from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import {TemporaryImage} from "../data/images";
import {TemporaryVideo} from "../data/videos";
import {Modal} from "./modal/Modal";
import { playCircleOutline, close } from "ionicons/icons";

interface TemporaryFilePreviewProps extends StyleReactProps {
    file: FileOnDisk,
}

export const TemporaryFilePreview: React.FunctionComponent<TemporaryFilePreviewProps> = props => {
    const [url, setUrl] = React.useState<string | null>(null)

    React.useEffect(() => {
        if (props.file) {
            fileHandling.getPublicUrl(props.file).then(setUrl)
        }
    }, [props.file])

    if (url === null) {
        return <IonLoading />
    }

    return <IonImg src={url} style={props.style} className={props.className} class={props.class} />
}

interface TemporaryImagePreviewProps extends StyleReactProps {
    image: TemporaryImage,
    bounds?: { width: number, height: number },
    children?: React.ReactNode,
}

export const TemporaryImagePreview: React.FunctionComponent<TemporaryImagePreviewProps> = props => {
    let width: string|number = '100%';
    let height: string|number = '100%';

    if (props.bounds) {
        width = props.image.width;
        height = props.image.height;

        if (width > props.bounds.width) {
            height = height * props.bounds.width / width;
            width = props.bounds.width;
        }
        if (height > props.bounds.height) {
            width = width * props.bounds.height / height;
            height = props.bounds.height;
        }
    }

    // The image is rotated using CSS. Because of this, it will always act as though it is
    // in the original orientation. To counteract this, the wrapper is sized accordingly.
    const aspectRatio = props.image.width / props.image.height;

    // In order to size the image correctly, a percentual offset is needed.
    let verticalOffset: number|string = 0;
    if (props.image.rotation === 90 || props.image.rotation === 270) {
        const ratioForCalculation = (aspectRatio < 1) ? aspectRatio : 1 / aspectRatio;
        verticalOffset = `-${(1 - ratioForCalculation) * 100}%`;
    }

    return <div style={{
        aspectRatio,
        width: '100%',
        flex: 10,
        position: 'relative',
        maxWidth: width,
        maxHeight: height,
    }}>
        <TemporaryFilePreview
            file={props.image}
            style={{ ...props.style, position: 'absolute', left: verticalOffset, right: verticalOffset, top: 0, bottom: 0, transform: `rotate(${props.image.rotation}deg)` }}
        />
        {props.children}
    </div>
}

interface TemporaryVideoPreviewProps extends StyleReactProps {
    video: TemporaryVideo,
}

export const TemporaryVideoPreview: React.FunctionComponent<TemporaryVideoPreviewProps> = props => {
    const [ modalOpen, setModalOpen ] = React.useState<boolean>(false);
    const [ videoUrl, setVideoUrl ] = React.useState<string | null>(null);
    const videoElement = React.useRef<HTMLVideoElement>(null);


    const playVideo = () => {
        setModalOpen(true);
        fileHandling.getPublicUrl(props.video).then(url => setVideoUrl(url));
        if (videoElement.current) {
            videoElement.current.play();
        }
    };

    const stopPlayingVideo = () => {
        setModalOpen(false);
        if (videoElement.current) {
            videoElement.current.pause();
        }
    };

    return <React.Fragment>
        <Modal open={modalOpen} onClose={stopPlayingVideo}>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonTitle>{props.video.fileName}</IonTitle>
                    <IonButtons slot="end" className="ion-padding-end">
                        <IonButton onClick={stopPlayingVideo}>
                            <IonIcon icon={close} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent style={{ background: 'red' }}>
                {videoUrl !== null ? <video
                    ref={videoElement}
                    src={videoUrl}
                    controls
                    autoPlay
                    onEnded={stopPlayingVideo}
                    style={{ width: '100%', height: '100%' }}
                /> : <IonLoading/>}
            </IonContent>
        </Modal>

        <div onClick={playVideo} style={{ cursor: 'pointer', position: 'relative', width: '100%', height: '100%' }}>
            <TemporaryFilePreview
              file={props.video.thumbnail}
              style={{ ...props.style, position: 'absolute', inset: 1 }}
              className={props.className}
              class={props.class}
            />
            <div style={{ position: 'absolute', inset: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <IonIcon icon={playCircleOutline} size="large" style={{ color: 'white', filter: 'drop-shadow( 0 0 10px rgba(0, 0, 0, .5))'}} />
            </div>
        </div>

    </React.Fragment>
}