import React from "react";
import {Storage} from "@ionic/storage";
import {apiClient} from "../data/api";

const defaultLanguage: keyof typeof languages = 'de';
type DefaultLanguage = typeof defaultLanguage;
export type Language = keyof typeof languages;
export type LanguageLabel = keyof typeof labels[DefaultLanguage];
export type LabelFunc = (label: LanguageLabel, replacements?: Record<string, string>, lang?: Language) => string;

const languages: { [key: string]: string } = {
  de: 'Deutsch',
  en: 'English',
}

const labels: Record<Language, Record<string, string>> = {
  en: {
    'language': 'Language',
    'home.title': 'Welcome {user} to the PowderGuide App',
    'home.text': `
      With this app you can create a ConditionsReport and upload it directly to our website.
      We hope you like this app and wish all users a snowy and accident-free season. <br/>
      If you need help with the operation, please write us an email to
      <a href="{mail}">{mail}</a>
    `,
    'home.signoff': 'Thank you! Your PG - Team',
    'home.cr.create': 'Create ConditionsReport',
    'home.cr.edit': 'Edit Conditions',
    'home.cr.discard': 'Discard last edited CR',
    'home.fixed_login.title': 'Please sign in',
    'home.fixed_login.message': 'To use the embedded CR-App, please use the sign-in function on the top right of the website.',
    'home.action.logout': 'Logout',

    'login.error.invalid_credentials': 'Login failed: Username or password incorrect',
    'login.username': 'Username',
    'login.password': 'Password',
    'login.no_internet.title': 'No internet connection',
    'login.no_internet.message': 'A internet connection is required while logging in.',
    'login.action.login': 'Login',
    'login.action.password_forgotten': 'Forgot password?',
    'login.action.register': 'Register',

    'video_picker.title': 'Videos',
    'video_picker.select': 'Select videos',
    'video_picker.action.delete': 'Delete',
    'video_picker.error.file_too_large': 'File {fileName} exceeds maximum filesize of {maxFileSizeMb}MB.',
    'video_picker.error.video_too_long': 'Video {fileName} exceeds maximum duration of {maxDurationSeconds}s.',
    'video_picker.error.maximum_exceeded': 'Maximum number of {maxVideos} videos exceeded.',
    'video_picker.error.generic': 'An error ocurred',

    'image_picker.title': 'Images',
    'image_picker.select': 'Select images',
    'image_picker.info.minimum_required': 'At least one image must be selected',
    'image_picker.info.main_image': 'The first image is always the main image',
    'image_picker.error.too_large': 'File {fileName} exceeds maximum filesize of {maxFileSizeMb}MB.',

    'location_create.title': 'Create Location',
    'location_create.label': 'Name',
    'location_create.creating': 'Creating location...',
    'location_create.label.placeholder': 'Name of the location',
    'location_create.error.no_internet.title': 'No internet connection',
    'location_create.error.no_internet.message': 'An internet connection is required to create a location',
    'location_create.error.generic': 'An error occurred',
    'location_create.action.create': 'Create',
    'location_create.action.cancel': 'Cancel',

    'location_select.title': 'Location',
    'location_select.action.cancel': 'Cancel',
    'location_select.search': 'Search',
    'location_select.action.confirm': 'OK',
    'location_select.error.no_internet.title': 'No internet connection',
    'location_select.error.no_internet.message.line_1': 'An internet connection is required to load the list of spots.',
    'location_select.error.no_internet.message.line_2': 'After the list of spots has been loaded once, no connection is required anymore.',
    'location_select.error.no_results.title': 'No results found',
    'location_select.error.no_results.message': 'Could not find any locations matching the search term.',

    'editing.step_1': 'Location',
    'editing.step_2': 'Snow',
    'editing.step_3': 'Risks',
    'editing.step_4': 'Overall impression',
    'editing.action.next': 'Next',
    'editing.action.back': 'Previous',
    'editing.action.send': 'Send',
    'editing.required_fields': 'Fields marked with * are required',

    'submit.action.send': 'Send',
    'submit.step.check_user': 'Check if user is signed in',
    'submit.step.upload_image': 'Upload image: {fileName}',
    'submit.step.upload_video': 'Upload video: {fileName}',
    'submit.step.submit_cr': 'Submit ConditionsReport',
    'submit.error.no_internet.title': 'No internet connection',
    'submit.error.no_internet.message': 'A ConditionsReport can only be submitted with an active internet connection.',
    'submit.error.validation.title': 'Validation errors',
    'submit.error.not_signed_in_or_no_internet': 'User is not signed in or server cannot be reached. Please make sure you are connected to the internet and try again. If the problem persists, please log out and log in again.',
    'submit.error.image_upload': 'Image {fileName} could not be uploaded',
    'submit.error.video_upload': 'Video {fileName} could not be uploaded',
    'submit.error.generic': 'Error while submitting the Conditions Report',
    'submit.error.unknown': 'An unknown error occurred',

    'cr.author': 'Author',
    'cr.title': 'Title',
    'cr.title.validation.exists': 'Title must not be empty',
    'cr.overall_rating': 'Overall rating',
    'cr.overall_rating.validation.exists': 'Overall rating must not be empty',
    'cr.overall_trend': 'Trend',
    'cr.overall_trend.validation.exists': 'Trend must not be empty',
    'cr.overall_description': 'Free text...',
    'cr.exposition': 'Exposition',
    'cr.exposition.validation.exists': 'Please select exposition',
    'cr.location': 'Location',
    'cr.location.select': 'Select location',
    'cr.location.select_or_add': 'OR',
    'cr.location.add': 'Add location',
    'cr.location.validation.exists': 'Please select location',
    'cr.date': 'Date',
    'cr.date.validation.exists': 'Please select date',
    'cr.altitude': 'Altitude',
    'cr.altitude.all': 'All altitudes',
    'cr.altitude.select': 'Select altitude',
    'cr.altitude.validation.exists': 'Please select altitude',
    'cr.kind': 'Kind of Tour',
    'cr.kind.skitour': 'Skitour',
    'cr.kind.freeride': 'Freeride',
    'cr.kind.other': 'Other',
    'cr.avalanche_warning_level': 'Avalanche warning level',
    'cr.avalanche_warning_level.0': 'No information',
    'cr.avalanche_warning_level.1': 'Low',
    'cr.avalanche_warning_level.2': 'Medium',
    'cr.avalanche_warning_level.3': 'Considerable',
    'cr.avalanche_warning_level.4': 'High',
    'cr.avalanche_warning_level.5': 'Very High',
    'cr.avalanche_warning_level.validation.exists': 'Please select avalanche warning level',

    'cr.videos': 'Videos',
    'cr.video_num': 'Video {number}',
    'cr.images': 'Images',
    'cr.image_num': 'Image {number}',

    'cr.snow_condition': 'Snow condition',
    'cr.snow_condition.description': 'Free text...',
    'cr.snow_condition.rating': 'Rating',
    'cr.snow_condition.rating.validation.exists': 'Snow condition: Rating must be specified',
    'cr.snow_condition.descent_to_valley': 'Descent to valley',
    'cr.snow_condition.descent_to_height': 'Descent to height of {descentToHeight}m',
    'cr.snow_condition.descent_to_height.validation.exists': 'Height must be specified',
    'cr.snow_condition.descent_through_forest': 'Descent through forest',
    'cr.snow_condition.blown_backs': 'Blown backs',
    'cr.snow_condition.covered_shoes': 'Covered shoes',
    'cr.snow_condition.variations_of_snow_cover': 'Variations of snow cover',
    'cr.snow_condition.fresh_snow': 'Neuschnee',
    'cr.snow_condition.fresh_snow.validation.exists': 'Fresh snow must be specified',

    'cr.snow_quality': 'Schneequalität',
    'cr.snow_quality.rating': 'Rating',
    'cr.snow_quality.rating.validation.exists': 'Snow quality: Rating must be specified',
    'cr.snow_quality.description': 'Free text...',
    'cr.snow_quality.old_snow': 'Hard, pressed snow',
    'cr.snow_quality.breakable_crust': 'Breakable crust',
    'cr.snow_quality.slush': 'Slush',
    'cr.snow_quality.firn': 'Firn',
    'cr.snow_quality.wet_snow': 'Wet snow',
    'cr.snow_quality.powder': 'Powder snow',
    'cr.snow_quality.summer_snow': 'Summer snow',
    'cr.snow_quality.jelly_snow': 'Jelly snow',
    'cr.snow_quality.wind_drifted': 'Wind drifted snow',
    'cr.snow_quality.descent_through_forest': 'Descent through forest',
    'cr.snow_quality.wind_drifted_intensity': 'Wind drifted snow intensity',
    'cr.snow_quality.wind_drifted_intensity.low': 'Low',
    'cr.snow_quality.wind_drifted_intensity.medium': 'Medium',
    'cr.snow_quality.wind_drifted_intensity.high': 'High',
    'cr.snow_quality.wind_drifted_intensity.validation.exists': 'Intensity must be specified',
    'cr.snow_quality.wind_drifted_exposition': 'Wind drifted snow exposition',
    'cr.snow_quality.wind_drifted_exposition.validation.exists': 'Exposition must be specified',

    'cr.risk.description': 'Free text...',
    'cr.risk.wind_intensity': 'Wind intensity',
    'cr.risk.wind_intensity.0': 'No Wind (0 km/h)',
    'cr.risk.wind_intensity.1': 'Low (< 20 km/h)',
    'cr.risk.wind_intensity.2': 'Medium (20 – 40 km/h)',
    'cr.risk.wind_intensity.3': 'High (40 – 60 km/h)',
    'cr.risk.wind_intensity.4': 'Extreme (> 60 km/h)',
    'cr.risk.wind_intensity.validation.exists': 'Please select wind intensity',
    'cr.risk.wind_direction': 'Wind',
    'cr.risk.wind_Direction.validation.exists': 'Please select wind direction',
    'cr.risk.no_warning_signs': 'No warning signs',
    'cr.risk.abort': 'Abort the tour',
    'cr.risk.old_snow_problem': 'Old snow problem',
    'cr.risk.cracking': 'Cracking (When entering the snow cover)',
    'cr.risk.rockfall': 'Rockfall danger',
    'cr.risk.crevices': 'Crevasse or fall danger',
    'cr.risk.diurnal_warming': 'Diurnal warming',
    'cr.risk.much_fresh_snow': 'Much fresh snow',
    'cr.risk.much_water': 'Much water in the snow cover',
    'cr.risk.hissing_noise': 'Woom- or hissing noise',
    'cr.risk.fresh_avalanches': 'Fresh avalanches',
    'cr.risk.fresh_avalanches.exposition': 'Exposition of the avalanche',
    'cr.risk.fresh_avalanches.exposition.validation.exists': 'Fresh Avalanches: Exposition must be specified',
    'cr.risk.fresh_avalanches.description': 'Description of the avalanche',
    'cr.risk.fresh_avalanches.altitude': 'Altitude of the avalanche: {altitude}m',
    'cr.risk.fresh_avalanches.altitude.validation.exists': 'Fresh Avalanches: Altitude must be specified',
    'cr.risk.fresh_avalanches.size': 'Size of the avalanche',
    'cr.risk.fresh_avalanches.size.0': 'Small',
    'cr.risk.fresh_avalanches.size.1': 'Medium',
    'cr.risk.fresh_avalanches.size.2': 'Large',
    'cr.risk.fresh_avalanches.size.3': 'Very large',
    'cr.risk.fresh_avalanches.size.4': 'Extremely large',
    'cr.risk.fresh_avalanches.size.5': 'Unknown',
    'cr.risk.fresh_avalanches.size.validation.exists': 'Fresh Avalanches: Size must be specified',
    'cr.risk.fresh_avalanches.type': 'Type of avalanche',
    'cr.risk.fresh_avalanches.type.dry_loose': 'Dry loose snow avalanche',
    'cr.risk.fresh_avalanches.type.wet_loose': 'Wet loose snow avalanche',
    'cr.risk.fresh_avalanches.type.dry_slab': 'Dry slab avalanche',
    'cr.risk.fresh_avalanches.type.wet_slab': 'Wet slab avalanche',
    'cr.risk.fresh_avalanches.type.gliding': 'Gliding avalanche',
    'cr.risk.fresh_avalanches.type.wet': 'Wet snow avalanche',
    'cr.risk.fresh_avalanches.type.cornice_break': 'Cornice break',
    'cr.risk.fresh_avalanches.type.unknown': 'Unknown',
    'cr.risk.fresh_avalanches.type.validation.exists': 'Fresh Avalanches: Type must be specified',
  },
  de: {
    'language': 'Sprache',
    'home.title': 'Willkommen {user} in der PowderGuide App',
    'home.text': `
      Mit dieser App könnt ihr einen ConditionsReport erstellen und direkt auf unserer
      Webseite hochladen. Wir hoffen euch gefällt diese App und wünschen allen Usern
      eine schneereiche & unfallfreie Saison. <br/>
      Falls ihr Hilfe zur Bedienung benötigen solltet, schreibt uns eine Mail an
      <a href="mailto:{mail}">{mail}</a>
    `,
    'home.signoff': 'Vielen Dank! Euer PG - Team',
    'home.cr.create': 'ConditionsReport erstellen',
    'home.cr.edit': 'ConditionsReport bearbeiten',
    'home.cr.discard': 'Zuletzt bearbeiteten CR verwerfen',
    'home.fixed_login.title': 'Bitte Anmelden',
    'home.fixed_login.message': 'Um die PowderGuide App über die Webseite nutzen zu können, nutzen Sie bitte die Anmelde-Funktion oben rechts.',
    'home.action.logout': 'Abmelden',

    'login.error.invalid_credentials': 'Anmeldung fehlgeschlagen: Nutzername oder Passwort falsch',
    'login.username': 'Benutzername',
    'login.password': 'Passwort',
    'login.no_internet.title': 'Keine Internetverbindung',
    'login.no_internet.message': 'Zum Anmelden ist eine Internetverbindung notwendig',
    'login.action.login': 'Anmelden',
    'login.action.password_forgotten': 'Passwort vergessen?',
    'login.action.register': 'Registrieren',

    'video_picker.title': 'Videos',
    'video_picker.select': 'Videos auswählen',
    'video_picker.action.delete': 'Löschen',
    'video_picker.error.file_too_large': 'Datei {fileName} ist zu groß. Maximal {maxFileSizeMb}MB erlaubt.',
    'video_picker.error.video_too_long': 'Leider ist das gewählte Video "{fileName}" zu lang. Bitte wähle ein Video mit max. {maxFileSizeMb} MB Dateigröße und max. ${maxDurationSeconds} Sekunden Länge.',
    'video_picker.error.maximum_exceeded': 'Es dürfen maximal {maxVideos} Videos hochgeladen werden.',
    'video_picker.error.generic': 'Ein Fehler ist aufgetreten',

    'image_picker.title': 'Bilder',
    'image_picker.select': 'Bilder auswählen',
    'image_picker.info.minimum_required': 'Es muss mindestens ein Bild ausgewählt werden',
    'image_picker.info.main_image': 'Das erste Bild ist immer das Hauptbild',
    'image_picker.error.too_large': 'Datei {fileName} ist zu groß. Maximal {maxFileSizeMb}MB erlaubt.',

    'location_create.title': 'Ort erstellen',
    'location_create.label': 'Name',
    'location_create.creating': 'Ort wird erstellt...',
    'location_create.label.placeholder': 'Hier Name des Spots bestimmen',
    'location_create.error.no_internet.title': 'Keine Internetverbindung',
    'location_create.error.no_internet.message': 'Zum Anlegen eines neuen Spots ist eine Internetverbindung notwendig',
    'location_create.error.generic': 'Ein Fehler ist aufgetreten',
    'location_create.action.create': 'Erstellen',
    'location_create.action.cancel': 'Abbrechen',

    'location_select.title': 'Ort',
    'location_select.action.cancel': 'Abbrechen',
    'location_select.action.confirm': 'OK',
    'location_select.search': 'Suche',
    'location_select.error.no_internet.title': 'Keine Internetverbindung',
    'location_select.error.no_internet.message.line_1': 'Zum erstmaligen Laden der Spots ist eine Internetverbindung notwendig.',
    'location_select.error.no_internet.message.line_2': 'Nach dem die Liste der Spots einmal geladen wurde, ist keine Verbindung mehr notwendig.',
    'location_select.error.no_results.title': 'Keine Ergebnisse gefunden',
    'location_select.error.no_results.message': 'Es konnten keine Spots gefunden werden, die dem Suchbegriff entsprechen.',

    'editing.step_1': 'Örtlichkeit',
    'editing.step_2': 'Schnee',
    'editing.step_3': 'Gefahren',
    'editing.step_4': 'Gesamteindruck',
    'editing.action.next': 'Weiter',
    'editing.action.back': 'Zurück',
    'editing.action.send': 'Absenden',
    'editing.required_fields': 'Mit * markierte Felder sind Pflichtfelder',

    'submit.action.send': 'Absenden',
    'submit.step.check_user': 'Überprüfe ob Benutzer angemeldet ist',
    'submit.step.upload_image': 'Bild hochladen: {fileName}',
    'submit.step.upload_video': 'Video hochladen: {fileName}',
    'submit.step.submit_cr': 'ConditionsReport absenden',
    'submit.error.no_internet.title': 'Keine Internetverbindung',
    'submit.error.no_internet.message': 'Ein ConditionsReport kann nur mit bestehender Internetverbindung abgesendet werden.',
    'submit.error.validation.title': 'Es gibt Validierungsfehler',
    'submit.error.not_signed_in_or_no_internet': 'Benutzer ist nicht angemeldet oder Server kann nicht erreicht werden. Bitte stellen Sie sicher, dass Sie mit dem Internet verbunden sind und versuchen Sie es erneut. Sollte das Problem weiterhin bestehen, melden Sie sich bitte ab und wieder an.',
    'submit.error.image_upload': 'Bild {fileName} konnte nicht hochgeladen werden',
    'submit.error.video_upload': 'Video {fileName} konnte nicht hochgeladen werden',
    'submit.error.generic': 'Fehler beim Absenden des Conditions Reports',
    'submit.error.unknown': 'Ein unbekannter Fehler ist aufgetreten',

    'cr.author': 'Autor',
    'cr.title': 'Titel',
    'cr.title.validation.exists': 'Titel darf nicht leer sein',
    'cr.overall_rating': 'Gesamteindruck',
    'cr.overall_rating.validation.exists': 'Gesamteindruck darf nicht leer sein',
    'cr.overall_trend': 'Trend',
    'cr.overall_trend.validation.exists': 'Trend darf nicht leer sein',
    'cr.overall_description': 'Freitext...',
    'cr.exposition': 'Exposition',
    'cr.exposition.validation.exists': 'Bitte Exposition auswählen',
    'cr.location': 'Ort',
    'cr.location.select': 'Ort auswählen',
    'cr.location.select_or_add': 'ODER',
    'cr.location.add': 'Ort hinzufügen',
    'cr.location.validation.exists': 'Bitte Ort auswählen',
    'cr.date': 'Datum',
    'cr.date.validation.exists': 'Bitte Datum auswählen',
    'cr.altitude': 'Höhe',
    'cr.altitude.all': 'In allen Höhenlagen',
    'cr.altitude.select': 'Höhe auswählen',
    'cr.altitude.validation.exists': 'Bitte Höhe auswählen',
    'cr.kind': 'Art der Unternehmung',
    'cr.kind.skitour': 'Skitour',
    'cr.kind.freeride': 'Freeride',
    'cr.kind.other': 'Sonstiges',
    'cr.avalanche_warning_level': 'Lawinenwarnstufe',
    'cr.avalanche_warning_level.0': 'Keine Angabe',
    'cr.avalanche_warning_level.1': 'Gering',
    'cr.avalanche_warning_level.2': 'Mäßig',
    'cr.avalanche_warning_level.3': 'Erheblich',
    'cr.avalanche_warning_level.4': 'Groß',
    'cr.avalanche_warning_level.5': 'Sehr Groß',
    'cr.avalanche_warning_level.validation.exists': 'Bitte Lawinenwarnstufe auswählen',

    'cr.videos': 'Videos',
    'cr.video_num': 'Video {number}',
    'cr.images': 'Bilder',
    'cr.image_num': 'Bild {number}',

    'cr.snow_condition': 'Schneelage',
    'cr.snow_condition.description': 'Freitext...',
    'cr.snow_condition.rating': 'Bewertung',
    'cr.snow_condition.rating.validation.exists': 'Schneelage: Bewertung muss angegeben werden',
    'cr.snow_condition.descent_to_valley': 'Abfahrt ins Tal',
    'cr.snow_condition.descent_to_height': 'Bis zu einer Höhe von {descentToHeight}m',
    'cr.snow_condition.descent_to_height.validation.exists': 'Höhe muss angegeben werden',
    'cr.snow_condition.descent_through_forest': 'Abfahrt durch den Wald',
    'cr.snow_condition.blown_backs': 'Abgeblasene Rücken',
    'cr.snow_condition.covered_shoes': 'Latschen bedeckt',
    'cr.snow_condition.variations_of_snow_cover': 'Starke Schwankung der Schneedecke',
    'cr.snow_condition.fresh_snow': 'Neuschnee',
    'cr.snow_condition.fresh_snow.validation.exists': 'Neuschnee muss angegeben werden',

    'cr.snow_quality': 'Schneequalität',
    'cr.snow_quality.rating': 'Bewertung',
    'cr.snow_quality.rating.validation.exists': 'Schneequalität: Bewertung muss angegeben werden',
    'cr.snow_quality.description': 'Freitext...',
    'cr.snow_quality.old_snow': 'Harter, gepresster Schnee',
    'cr.snow_quality.breakable_crust': 'Bruchharsch',
    'cr.snow_quality.slush': 'Faulschnee',
    'cr.snow_quality.firn': 'Firn',
    'cr.snow_quality.wet_snow': 'Nassschnee',
    'cr.snow_quality.powder': 'Pulverschnee',
    'cr.snow_quality.summer_snow': 'Sommerschnee',
    'cr.snow_quality.jelly_snow': 'Sulz',
    'cr.snow_quality.wind_drifted': 'Triebschnee',
    'cr.snow_quality.descent_through_forest': 'Abfahrt durch den Wald',
    'cr.snow_quality.wind_drifted_intensity': 'Triebschneeintensität',
    'cr.snow_quality.wind_drifted_intensity.validation.exists': 'Intensität muss angegeben werden',
    'cr.snow_quality.wind_drifted_intensity.low': 'Gering',
    'cr.snow_quality.wind_drifted_intensity.medium': 'Mittel',
    'cr.snow_quality.wind_drifted_intensity.high': 'Stark',
    'cr.snow_quality.wind_drifted_exposition': 'Exposition des Triebschnees',
    'cr.snow_quality.wind_drifted_exposition.validation.exists': 'Exposition muss angegeben werden',

    'cr.risk.description': 'Freitext...',
    'cr.risk.wind_intensity': 'Windintensität',
    'cr.risk.wind_intensity.0': 'Kein Wind (0 km/h)',
    'cr.risk.wind_intensity.1': 'Gering (< 20 km/h)',
    'cr.risk.wind_intensity.2': 'Mittel (20 – 40 km/h)',
    'cr.risk.wind_intensity.3': 'Stark (40 – 60 km/h)',
    'cr.risk.wind_intensity.4': 'Extrem (> 60 km/h)',
    'cr.risk.wind_intensity.validation.exists': 'Bitte Windintensität auswählen',
    'cr.risk.wind_direction': 'Wind',
    'cr.risk.wind_Direction.validation.exists': 'Bitte Windrichtung auswählen',
    'cr.risk.no_warning_signs': 'Keine Gefahrenzeichen',
    'cr.risk.abort': 'Abbruch der Tour',
    'cr.risk.old_snow_problem': 'Altschneeproblem',
    'cr.risk.cracking': 'Rissbildung (Beim Betreten der Schneedecke)',
    'cr.risk.rockfall': 'Steinschlaggefahr',
    'cr.risk.crevices': 'Spalten- bzw. Absturzgefahr',
    'cr.risk.diurnal_warming': 'Tageszeitliche Erwärmung',
    'cr.risk.much_fresh_snow': 'Viel Neuschnee',
    'cr.risk.much_water': 'Viel Wasser in der Schneedecke',
    'cr.risk.hissing_noise': 'Wumm- bzw. Zischgeräusche',
    'cr.risk.fresh_avalanches': 'Frische Lawinen',
    'cr.risk.fresh_avalanches.exposition': 'Exposition der Lawine',
    'cr.risk.fresh_avalanches.exposition.validation.exists': 'Frische Lawinen: Exposition muss angegeben werden',
    'cr.risk.fresh_avalanches.description': 'Beschreibung der Lawine',
    'cr.risk.fresh_avalanches.altitude': 'Höhenlage der Lawine: {altitude}m',
    'cr.risk.fresh_avalanches.altitude.validation.exists': 'Frische Lawinen: Höhenlage muss angegeben werden',
    'cr.risk.fresh_avalanches.size': 'Größe der Lawine',
    'cr.risk.fresh_avalanches.size.0': 'Klein',
    'cr.risk.fresh_avalanches.size.1': 'Mittel',
    'cr.risk.fresh_avalanches.size.2': 'Groß',
    'cr.risk.fresh_avalanches.size.3': 'Sehr groß',
    'cr.risk.fresh_avalanches.size.4': 'Extrem groß',
    'cr.risk.fresh_avalanches.size.5': 'Unbekannt',
    'cr.risk.fresh_avalanches.size.validation.exists': 'Frische Lawinen: Größe muss angegeben werden',
    'cr.risk.fresh_avalanches.type': 'Art der Lawine',
    'cr.risk.fresh_avalanches.type.dry_loose': 'Trockene Lockerschneelawine',
    'cr.risk.fresh_avalanches.type.wet_loose': 'Nasse Lockerschneelawine',
    'cr.risk.fresh_avalanches.type.dry_slab': 'Trockene Schneebrettlawine',
    'cr.risk.fresh_avalanches.type.wet_slab': 'Nasse Schneebrettlawine',
    'cr.risk.fresh_avalanches.type.gliding': 'Gleitschneelawine',
    'cr.risk.fresh_avalanches.type.wet': 'Nassschneelawine',
    'cr.risk.fresh_avalanches.type.cornice_break': 'Wechtenbruch',
    'cr.risk.fresh_avalanches.type.unknown': 'Unbekannt',
    'cr.risk.fresh_avalanches.type.validation.exists': 'Frische Lawinen: Art muss angegeben werden',
  },
};

export function getLabel(
  label: LanguageLabel,
  locale: Language,
  replacements: Record<string, string> = { },
): string {
  let translated: string = labels[locale][label] || labels.en[label] || label;
  for (const [key, value] of Object.entries(replacements)) {
    translated = translated.replaceAll(`{${key}}`, value);
  }
  return translated;
}


interface LanguageStateContext {
  locales: Record<Language, string>,
  locale: Language,
  setLocale: (locale: Language) => void,
  label: LabelFunc,
}

const LanguageStateContext = React.createContext<LanguageStateContext|null>(null);

export const LanguageStateContextProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({children}) => {
  const LOCALE_KEY = 'locale';
  const [ locale, setLocale ] = React.useState<Language>(defaultLanguage);
  const [ storage ] = React.useState<Storage>(new Storage());

  const label = (label: LanguageLabel, replacements: Record<string, string> = { }, lang?: Language) => getLabel(label, lang ?? locale, replacements);

  const updateLocale = (locale: Language) => {
    storage!.set(LOCALE_KEY, locale);
    setLocale(locale);
    apiClient.setLocale(`${locale}`);
  }

  // React.useEffect(() => {
  //   storage.create();
  //
  //   storage.get(LOCALE_KEY).then((locale: Language | null) => {
  //     if (locale) {
  //       setLocale(locale);
  //       return;
  //     }
  //
  //     Device.getLanguageCode().then(code => {
  //       if (Object.keys(languages).includes(code.value)) {
  //         updateLocale(code.value as Language);
  //       }
  //     })
  //   })
  // }, [ ])

  return (
    <LanguageStateContext.Provider value={{ locale, setLocale: updateLocale, label, locales: languages }}>
      {children}
    </LanguageStateContext.Provider>
  );
};

export function useI18n(): LanguageStateContext {
  const context = React.useContext(LanguageStateContext);
  if (context === null) {
    throw new Error('useLocale must be used within a LanguageStateContextProvider');
  }
  return context;
}
