import React, {useEffect, useState} from 'react';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonRadio,
    IonRadioGroup,
    IonSearchbar,
    IonTitle,
    IonToolbar
} from '@ionic/react'
import {ShortSpot, spotRepository} from "../../data/repository/Spot";
import {useOnlineState} from "../../data/Helpers";
import {Alert} from "@mui/material";
import {useI18n} from "../../i18n/i18n";
import {useSearchableCollection} from "../../data/search";

interface LocationSelectModalProps {
    location: string | null,
    onDismiss: () => void,
    onSelect: (uuid: string, location: ShortSpot | null) => void,
}

export const LocationSelectModal: React.FunctionComponent<LocationSelectModalProps> = (props) => {
    const [ uuid, setUuid] = useState<string | null>(props.location)
    const [ isOnline ] = useOnlineState();
    const { label } = useI18n();
    const search = useSearchableCollection<ShortSpot>(spot => [ spot.title ]);

    useEffect(() => {
        spotRepository.list(spots => search.update(spots))
            // eslint-disable-next-line no-console
            .catch(e => console.error(e))
    }, []);


    function submit() {
        if (uuid !== null) {
            const location = search.all.find(spot => spot.uuid === uuid) ?? null
            props.onSelect(uuid, location)
        }
    }

    return <React.Fragment>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonButton color="danger" onClick={() => props.onDismiss()}>
                        {label('location_select.action.cancel')}
                    </IonButton>
                </IonButtons>
                <IonTitle>
                    {label('location_select.title')}
                </IonTitle>

                {uuid ? <IonButton slot="end" color="primary" onClick={submit}>{label('location_select.action.confirm')}</IonButton> : null}
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <IonSearchbar
              placeholder={label('location_select.search')}
              value={search.query}
              onIonInput={e => search.search(e.detail.value ?? '')}
            />

            { (search.empty && !isOnline) && <Alert severity="warning" className="ion-margin">
                <h2>{label('location_select.error.no_internet.title')}</h2>
                <p>{label('location_select.error.no_internet.message.line_1')}</p>
                <p>{label('location_select.error.no_internet.message.line_2')}</p>
            </Alert> }

            {(search.empty && isOnline) && <Alert severity="info" className="ion-margin">
                <h2>{label('location_select.error.no_results.title')}</h2>
                <p>{label('location_select.error.no_results.message')}</p>
            </Alert> }

            <IonRadioGroup value={uuid} onIonChange={e => setUuid(e.detail.value)}>
                {search.results.map(spot => <IonItem key={spot.uuid}>
                    <IonRadio color="primary" value={spot.uuid}>
                        {spot.title}
                    </IonRadio>
                </IonItem>)}
            </IonRadioGroup>
        </IonContent>
    </React.Fragment>
}