import {fileHandling, FileOnDisk, temporaryFiles} from "../files";
import {implementationChooser} from "../Helpers";
import {NativeImageHandlingBackend} from "./NativeImageHandlingBackend";
import {GenericImageHandlingBackend} from "./GenericImageHandlingBackend";

export interface TemporaryImage extends FileOnDisk {
  rotation: number,
  width: number,
  height: number,
}

export interface ImageHandlingBackend {
  canLoadPhotoFromCamera(): Promise<boolean>
  canLoadPhotoFromGallery(): Promise<boolean>
  loadPhotoFromCamera(maxSize: { width: number, height: number }): Promise<TemporaryImage|null>;
  loadPhotosFromGallery(maxSize: { width: number, height: number }): Promise<TemporaryImage[]>;
  rotateImage(image: TemporaryImage): Promise<TemporaryImage>;
}

export const imageHandling = implementationChooser<ImageHandlingBackend>({
  ios: () => new NativeImageHandlingBackend(fileHandling, temporaryFiles),
  android: () => new NativeImageHandlingBackend(fileHandling, temporaryFiles),
  web: () => new GenericImageHandlingBackend(fileHandling, temporaryFiles),
})
