import {IonInput} from "@ionic/react";
import React from "react";

interface PwdInputProps {
    label: string;
    value: string;
    type?: 'text' | 'email' | 'password';
    onChange: (value: string) => void;
}

export const PwdInput: React.FunctionComponent<PwdInputProps> = (props) => {
    return <IonInput
        type={props.type ?? 'text'}
        fill="solid"
        label={props.label}
        value={props.value}
        onIonInput={e => props.onChange(e.detail.value!)}
        labelPlacement="floating"
    />
}