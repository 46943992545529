import {Camera, CameraResultType, CameraSource, GalleryPhoto, Photo} from "@capacitor/camera";
import {TemporaryImage, ImageHandlingBackend} from ".";
import {FileHandlingBackend, TemporaryFileHandler} from "../files";

const PHOTO_CAPTURE_CANCELED = 'User cancelled photos app';

export class GenericImageHandlingBackend implements ImageHandlingBackend {
  constructor(
    protected fileHandling: FileHandlingBackend,
    protected temporaryFiles: TemporaryFileHandler,
  ) { }

  async canLoadPhotoFromCamera(): Promise<boolean> {
    return true;
  }

  async loadPhotoFromCamera(maxSize: { width: number; height: number; }): Promise<TemporaryImage|null> {
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      correctOrientation: true,
    }).catch((e: unknown) => {
      if (e instanceof Error && e.message === PHOTO_CAPTURE_CANCELED) {
        return null;
      }

      throw e;
    })

    if (photo === null) {
      return null;
    }

    const resized = await this.resizeImage(photo, maxSize.width, maxSize.height)
    const temporaryFile = await this.fileHandling.getFile(resized.path)

    return { ...temporaryFile, rotation: 0, width: resized.width, height: resized.height };
  }

  async canLoadPhotoFromGallery(): Promise<boolean> {
    return true;
  }

  async loadPhotosFromGallery(maxSize: { width: number; height: number; }): Promise<TemporaryImage[]> {
    const photos = await Camera.pickImages({
      correctOrientation: true,
    }).catch((e: unknown) => {
        if (e instanceof Error && e.message === PHOTO_CAPTURE_CANCELED) {
            return null;
        }

        throw e;
    })

    if (photos === null) {
      return [ ];
    }

    const promises = photos.photos.map(async photo => {
      const resized = await this.resizeImage(photo, maxSize.width, maxSize.height)
      const temporaryFile = await this.fileHandling.getFile(resized.path);

      return { ...temporaryFile, rotation: 0, width: resized.width, height: resized.height };
    })

    return await Promise.all(promises)
  }

  async rotateImage(image: TemporaryImage): Promise<TemporaryImage> {
    return { ...image, rotation: (image.rotation + 90) % 360, width: image.height, height: image.width };
  }

  private async resizeImage(
    photo: Photo|GalleryPhoto,
    maxWidth: number,
    maxHeight: number,
  ): Promise<{ width: number, height: number, path: string }> {
    const path = await this.ensurePhotoIsOnDisk(photo);

    const image = await this.loadAsImageTag(photo.webPath!);
    if (image.width <= maxWidth && image.height <= maxHeight) {
      // The image is already small enough
      return { width: image.width, height: image.height, path };
    }

    let width = image.width;
    let height = image.height;
    if (width >= height && width > maxWidth) {
      // width is the largest dimension, and it's too big.
      height *= maxWidth / width;
      width = maxWidth;
    } else if (height > maxHeight) {
      // either width wasn't over-size or height is the largest dimension
      // and the height is over-size
      width *= maxHeight / height;
      height = maxHeight;
    }

    const fileName = photo.webPath!.split('/').pop()!;
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    console.log({ canvas })

    const ctx = canvas.getContext('2d');
    ctx!.drawImage(image, 0, 0, width, height);

    const blob: Blob = await new Promise(resolve => canvas.toBlob(blob => {
      if (blob) {
        resolve(blob)
      }
    }, 'image/jpeg', 0.85))

    const resizedPath = await this.temporaryFiles.writeToDisk(fileName, blob)

    return { width, height, path: resizedPath };
  }

  private async ensurePhotoIsOnDisk(photo: Photo|GalleryPhoto): Promise<string> {
    if (photo.path) {
      return photo.path;
    }

    const fileName = photo.webPath!.split('/').pop()!;
    const response = await fetch(photo.webPath!);
    const blob = await response.blob();

    return this.temporaryFiles.writeToDisk(fileName, blob);
  }


  private loadAsImageTag(file: string): Promise<HTMLImageElement> {
    return new Promise(resolve => {
      const image = document.createElement('img');
      image.addEventListener('load', () => resolve(image));
      image.src = file
    });
  }
}