import {FileHandlingBackend, FileOnDisk} from "../files";

export class FileHandlingUrlCacheDecorator implements FileHandlingBackend {
  constructor(private backend: FileHandlingBackend) {}

  private urlCache: Record<string, string> = {}

  async getFile(path: string): Promise<FileOnDisk> {
    return this.backend.getFile(path);
  }

  async getPublicUrl(file: FileOnDisk): Promise<string> {
    if (this.urlCache[file.nativePath]) {
      return this.urlCache[file.nativePath]
    }

    const url = await this.backend.getPublicUrl(file)
    this.urlCache[file.nativePath] = url

    return url
  }

  async readFile(file: FileOnDisk): Promise<Blob> {
    return this.backend.readFile(file);
  }

  async deleteFile(file: FileOnDisk): Promise<void> {
    return this.backend.deleteFile(file);
  }
}