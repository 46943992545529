import {TokenStorage} from "./index";

/**
 * A token storage that always returns the same token passed in from the environment
 */
export class FixedTokenStorage implements TokenStorage {
  constructor(
    private readonly apiToken: string,
  ) { }

  async get(): Promise<string | null> {
    return this.apiToken;
  }

  async allowsModification(): Promise<boolean> {
    return false;
  }

  async set(token: string): Promise<void> {
    console.warn(`Trying to set token to ${token}, but environment.apiToken is set. Ignoring.`);
  }

  async remove(): Promise<void> {
    console.warn('Trying to remove token, but environment.apiToken is set. Ignoring.');
  }
}