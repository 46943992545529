import {FileTransfer, FileUploadResult} from "@awesome-cordova-plugins/file-transfer";
import {GenericApiClient} from "./GenericApiClient";
import {ApiError, FileUploadAbsoluteOptions} from "./index";

export class NativeApiClient extends GenericApiClient {
  async fileUploadAbsolute(options: FileUploadAbsoluteOptions): Promise<string> {
    let result: FileUploadResult;
    try {
      result = await FileTransfer.create().upload(
        options.file.nativePath,
        encodeURI(options.url),
        {
          chunkedMode: false,
          fileKey: options.field,
          fileName: options.file.fileName,
          headers: options.headers ?? { },
          httpMethod: options.method,
          mimeType: options.file.mimeType,
          params: options.additionalFields ?? { },
        }
      )
    } catch (e: any) {
      console.error(e);

      // In some cases, the plugin has issues reading the response,
      // but the result is still successful
      if (e.exception === 'java.io.EOFException' && e.http_status === 200) {
        return '';
      }

      throw JSON.stringify(e);
    }

    if (result.responseCode >= 400) {
      const body = JSON.parse(result.response);
      const message = body?.message ?? `Failed to upload ${options.file.fileName}`;
      throw new ApiError(result.responseCode, message, body);
    }

    return result.response;
  }
}