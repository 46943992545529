import {FilePicker, PickedFile} from "@capawesome/capacitor-file-picker";
import {MediaCapture} from "@awesome-cordova-plugins/media-capture";
import {Capacitor} from "@capacitor/core";
import {TemporaryVideo, VideoHandlingBackend} from ".";
import {FileHandlingBackend} from "../files";

const MEDIA_CAPTURE_ERROR_NO_MEDIA_FILES = 3;
const MEDIA_CAPTURE_PERMISSION_DENIED = 4;
const FILE_PICKER_NO_FILES_SELECTED = 'pickFiles canceled.';

export abstract class GenericVideoHandlingBackend implements VideoHandlingBackend {
  constructor(
    protected readonly fileHandling: FileHandlingBackend,
  ) { }

  abstract canLoadVideosFromCamera(): Promise<boolean>;
  abstract canLoadVideosFromGallery(): Promise<boolean>;

  protected abstract extractPathsFromGalleryFile(file: PickedFile): Promise<{ pathOnDisk: string, publicPath: string }>;
  protected abstract createVideoThumbnail(path: string, videoElement: HTMLVideoElement): Promise<string>;

  async loadVideosFromCamera(validateVideo: (data: { name: string; size: number; }, element: HTMLVideoElement) => string | null): Promise<TemporaryVideo[]> {
    const result = await MediaCapture.captureVideo({duration: 30, limit: 1, quality: 1}).catch((err: any) => {
      if ('code' in err && (err.code === MEDIA_CAPTURE_ERROR_NO_MEDIA_FILES || err.code === MEDIA_CAPTURE_PERMISSION_DENIED)) {
        return null;
      }

      throw err;
    })

    if (result === null) {
      return [ ];
    }

    if (!(result instanceof Array)) {
      throw result
    }

    // Preperation & validation
    const files = await Promise.all(result.map(async video => {
      const videoElement = await this.createVideoElement(Capacitor.convertFileSrc(video.fullPath))

      const validationError = validateVideo(video, videoElement)
      if (validationError) {
        throw validationError;
      }

      const temporaryFile = await this.fileHandling.getFile(video.fullPath)

      const thumbnailPath = await this.createVideoThumbnail(video.fullPath, videoElement)
      const thumbnail = await this.fileHandling.getFile(thumbnailPath)

      return { ...temporaryFile, thumbnail}
    }))

    return files;
  }

  async loadVideosFromGallery(validateVideo: (data: { name: string; size: number; }, element: HTMLVideoElement) => string | null): Promise<TemporaryVideo[]> {
    const result = await FilePicker.pickVideos({
      readData: true,
    }).catch((e: unknown) => {
      // Not really an error, the user just canceled the file picker
      if (e instanceof Error && e.message === FILE_PICKER_NO_FILES_SELECTED) {
        return null;
      }

      throw e;
    });

    if (result === null) {
      return [ ];
    }

    await new Promise(resolve => setTimeout(resolve, 200))

    const files: TemporaryVideo[] = [ ];
    for (const file of result.files) {
      const { pathOnDisk, publicPath } = await this.extractPathsFromGalleryFile(file);

      const videoElement = await this.createVideoElement(publicPath);
      const validationError = validateVideo(file, videoElement);
      if (validationError) {
        throw validationError;
      }

      const temporaryFile = await this.fileHandling.getFile(pathOnDisk);
      const thumbnailPath = await this.createVideoThumbnail(pathOnDisk, videoElement);
      const thumbnail = await this.fileHandling.getFile(thumbnailPath);

      files.push({ ...temporaryFile, thumbnail });
    }

    return files;
  }

  protected async createVideoElement(url: string): Promise<HTMLVideoElement> {
    const video = document.createElement('video');

    return new Promise((resolve, reject) => {
      video.preload = 'metadata';
      video.muted = true;
      video.playsInline = true;
      video.autoplay = false;

      // Required for iOS
      video.controls = true;

      video.addEventListener('loadedmetadata', () => {
        document.documentElement.removeChild(video);
        resolve(video)
      });
      video.addEventListener('error', e => {
        reject(e)
      });

      document.documentElement.appendChild(video)

      video.load();
      video.src = url;
    });
  }
}