import {implementationChooser} from "../Helpers";
import {WebLayoutData} from "./WebLayoutData";
import {NativeLayoutData} from "./NativeLayoutData";

export interface LayoutData {
  showLogoInHeader: boolean;
}

export const layoutData = implementationChooser<LayoutData>({
  web: () => new WebLayoutData(),
  ios: () => new NativeLayoutData(),
  android: () => new NativeLayoutData(),
})