import {PickedFile} from "@capawesome/capacitor-file-picker";
import {Capacitor} from "@capacitor/core";
import {VideoEditor} from "@whiteguru/capacitor-plugin-video-editor";
import {GenericVideoHandlingBackend} from "./GenericVideoHandlingBackend";
import {FileHandlingBackend, TemporaryFileHandler} from "../files";

export class AndroidVideoHandlingBackend extends GenericVideoHandlingBackend {
  constructor(
    fileHandling: FileHandlingBackend,
    private readonly temporaryFiles: TemporaryFileHandler
  ) {
    super(fileHandling);
  }

  async canLoadVideosFromCamera(): Promise<boolean> {
    return true;
  }
  async canLoadVideosFromGallery(): Promise<boolean> {
    return true;
  }

  // Android has issues with the resulting URLs, therefor the data is read
  // and copied to memory instead of handled as path. This is not ideal memory
  // wise but the only way to get it working on Android.
  // See: https://github.com/capawesome-team/capacitor-plugins/issues/158
  protected async extractPathsFromGalleryFile(file: PickedFile): Promise<{ pathOnDisk: string, publicPath: string }> {
    const pathOnDisk = await this.temporaryFiles.writeToDisk(file.name, file.data!);
    const publicPath = Capacitor.convertFileSrc(pathOnDisk);

    return { pathOnDisk, publicPath };
  }

  protected async createVideoThumbnail(path: string): Promise<string> {
    const result = await VideoEditor.thumbnail({
      path: path,
      width: 320,
      height: 240,
    })

    return result.file.path;
  }
}