import {ObservableTaskCollection} from "../data/Task";
import React, {useEffect} from "react";
import {IonItem, IonList, IonProgressBar} from "@ionic/react";

interface ObserverStatusProps {
    observer: ObservableTaskCollection,
}

export const ObserverStatus: React.FunctionComponent<ObserverStatusProps> = (props) => {
    const [listenerId, setListenerId] = React.useState<string | null>(null);
    const [total, setTotal] = React.useState<number>(0);
    const [done, setDone] = React.useState<number>(0);
    const [inProgress, setInProgress] = React.useState<number>(0);
    const [inProgressNames, setInProgressNames] = React.useState<string[]>([]);

    console.log(props.observer);
    useEffect(() => {
        setListenerId(props.observer.onStatusChange(() => {
            console.log(props.observer)
            setTotal(props.observer.getTasks().length)
            setDone(props.observer.getDoneTasks().length)

            const inProgressTasks = props.observer.getInProgressTasks()
            setInProgress(inProgressTasks.length)
            setInProgressNames(inProgressTasks.map(task => task.name))
        }))

        return () => {
            if (listenerId) {
                props.observer.offStatusChange(listenerId)
            }
        }
    }, [props.observer]);

    return <React.Fragment>
        <IonProgressBar value={done / total} buffer={inProgress / total}/>
        <IonList>
            <IonItem>{done} / {total}</IonItem>
            {inProgressNames.map(name => <IonItem key={name}>{name}</IonItem>)}
        </IonList>
    </React.Fragment>
}