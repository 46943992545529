import {fileHandling, FileOnDisk} from "../files";
import {implementationChooser} from "../Helpers";
import {environment} from "../../environment";
import {WebApiClient} from "./WebApiClient";
import {NativeApiClient} from "./NativeApiClient";

export class ApiError extends Error {
  constructor(
      public status: number,
      message: string,
      public body: any,
  ) {
    super(message);
  }

  validationErrors(): Record<string, string[]> | null {
    if (this.status !== 422 || !this.body || !this.body.detail) {
      return null;
    }

    return this.body?.detail ?? null;
  }
}

export interface RequestOptions {
  endpoint: string;
  method: 'GET' | 'POST';
  headers?: Record<string, string>;
  body?: Record<string, any> | FormData | null;
  throwOnFailure?: boolean,
}

export interface FileUploadOptions {
  token: string;
  endpoint: string;
  method: 'POST' | 'PATCH',
  field: string;
  file: FileOnDisk;
  additionalFields?: Record<string, any>;
}

export interface FileUploadAbsoluteOptions {
  url: string;
  method: 'POST' | 'PATCH',
  field: string;
  file: FileOnDisk;
  headers?: Record<string, string>;
  additionalFields?: Record<string, any>;
}

export interface ApiClient {
  setLocale(locale: string): void;
  request(options: RequestOptions): Promise<Response>;
  fileUpload<T>(options: FileUploadOptions): Promise<T>;
  fileUploadAbsolute(options: FileUploadAbsoluteOptions): Promise<string>
}

export const apiClient = implementationChooser<ApiClient>({
  web: () => new WebApiClient(environment.powderguideWebsiteUrl, fileHandling),
  ios: () => new NativeApiClient(environment.powderguideWebsiteUrl, fileHandling),
  android: () => new NativeApiClient(environment.powderguideWebsiteUrl, fileHandling),
})