import React, {useEffect} from 'react';
import {IonIcon, IonLabel, IonSelect, IonSelectOption, IonTextarea} from "@ionic/react";
import {IonItemWithValidation} from "../../components/ValidationWarning";
import {createOutline} from 'ionicons/icons'
import {StarSelector} from "../../components/StarSelector";
import {VideoPicker} from "../../components/VideoPicker";
import {ImagePicker} from "../../components/ImagePicker";
import {useLoginState} from "../../data/Login";
import {format} from 'date-fns'
import {TemporaryVideo} from "../../data/videos";
import {StepScope, StepValidationResult, ValidationScope} from "../../data/ConditionsReportEditing";
import {useI18n} from "../../i18n/i18n";
import {TemporaryImage} from "../../data/images";
import {Alert} from "@mui/material";

export interface Step4Data {
    title: string,
    hasCustomizedTitle: boolean,
    overallRating: number,
    overallDescription: string,
    overallTrend: number | null,
    indexOfFirstPicture: number,
    videos: TemporaryVideo[],
    images: TemporaryImage[],
}

export function Step4EmptyData(): Step4Data {
    return {
        title: '',
        hasCustomizedTitle: false,
        overallRating: 0,
        overallDescription: '',
        overallTrend: null,
        indexOfFirstPicture: 0,
        videos: [],
        images: [],
    };
}

export function validateStep4({data, label}: ValidationScope<Step4Data>): StepValidationResult<Step4Data> {
    const validation: StepValidationResult<Step4Data> = {}

    if (data.title.length === 0) {
        validation.title = [ label('cr.title.validation.exists') ]
    }

    if (data.overallRating === 0) {
        validation.overallRating = [ label('cr.overall_rating.validation.exists') ]
    }

    if (data.overallTrend === null) {
        validation.overallTrend = [ label('cr.overall_trend.validation.exists') ]
    }

    if (data.images.length === 0) {
        validation.images = [ label('image_picker.info.minimum_required') ]
    }

    return validation;
}

export const Step4Form: React.FunctionComponent<StepScope<Step4Data>> = (props) => {
    const { label } = useI18n();
    const {data, updateData, validation, showValidation, allData} = props
    const {user} = useLoginState()

    // Automatic filling of the title
    useEffect(() => {
        if (data.hasCustomizedTitle) {
            return;
        }
        if (allData.step1.location.name && allData.step1.date && user?.username) {
            const formattedDate = format(new Date(allData.step1.date), 'dd.MM.yyyy')
            updateData({title: `${allData.step1.location.name} am ${formattedDate} | ${user.username ?? ''}`})
        }
    }, [allData.step1.location.name, allData.step1.date, user])

    return <React.Fragment>
        <IonItemWithValidation errors={validation.title}>
            <IonTextarea
                value={data.title}
                onIonInput={e => updateData({title: e.detail.value!, hasCustomizedTitle: true})}
                autocapitalize="sentences"
            >
                <IonLabel slot="label">
                    <IonIcon icon={createOutline}/>
                    {label('cr.title')}*
                </IonLabel>
            </IonTextarea>
        </IonItemWithValidation>

        <IonItemWithValidation errors={validation.overallRating}>
            <IonLabel>Gesamteindruck*:</IonLabel>
            <StarSelector value={data.overallRating} onChange={rating => updateData({overallRating: rating})}
                          max={5}/>
        </IonItemWithValidation>

        <IonItemWithValidation errors={validation.overallTrend}>
            <IonSelect
                value={data.overallTrend}
                placeholder={label('cr.overall_trend') + '*'}
                onIonChange={e => updateData({overallTrend: e.detail.value})}
                interface="action-sheet"
            >
                <IonSelectOption value={-1}>Schlechter</IonSelectOption>
                <IonSelectOption value={0}>Bleibt Gleich</IonSelectOption>
                <IonSelectOption value={1}>Besser</IonSelectOption>
            </IonSelect>
        </IonItemWithValidation>

        <IonItemWithValidation errors={validation.overallDescription}>
            <IonTextarea
                placeholder={label('cr.overall_description')}
                value={data.overallDescription}
                onIonInput={e => updateData({overallDescription: e.detail.value!})}
                autocapitalize="sentences"
                label="Text"
            />
        </IonItemWithValidation>


        <VideoPicker
            max={3}
            maxSizeMb={200}
            maxLengthS={30}
            videos={data.videos}
            onChange={videos => updateData({videos})}
        />
        <ImagePicker
            maxWidthPx={2000}
            maxHeightPx={2000}
            maxSizeMb={10}
            images={data.images}
            onChange={images => updateData({images})}
        />
        {data.images.length === 0 && <Alert className="ion-margin" severity={showValidation ? 'warning' : 'info'}>
            {label('image_picker.info.minimum_required')}
        </Alert>}

    </React.Fragment>
}
