import {fileHandling, FileOnDisk, temporaryFiles} from "../files";
import {implementationChooser} from "../Helpers";
import {AndroidVideoHandlingBackend} from "./AndroidVideoHandlingBackend";
import {IosVideoHandlingBackend} from "./IosVideoHandlingBackend";
import {WebVideoHandlingBackend} from "./WebVideoHandlingBackend";

export interface TemporaryVideo extends FileOnDisk {
  thumbnail: FileOnDisk,
}

export interface VideoHandlingBackend {
  canLoadVideosFromCamera(): Promise<boolean>;
  loadVideosFromCamera(validateVideo: (data: { name: string, size: number }, element: HTMLVideoElement) => string | null): Promise<TemporaryVideo[]>;
  canLoadVideosFromGallery(): Promise<boolean>;
  loadVideosFromGallery(validateVideo: (data: { name: string, size: number }, element: HTMLVideoElement) => string | null): Promise<TemporaryVideo[]>;
}

export const videoHandling = implementationChooser<VideoHandlingBackend>({
  android: () => new AndroidVideoHandlingBackend(fileHandling, temporaryFiles),
  ios: () => new IosVideoHandlingBackend(fileHandling),
  web: () => new WebVideoHandlingBackend(fileHandling, temporaryFiles),
})