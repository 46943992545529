import {FileHandlingBackend} from "../files";
import {ApiClient, ApiError, FileUploadAbsoluteOptions, RequestOptions, FileUploadOptions} from "./index";

export abstract class GenericApiClient implements ApiClient {

  protected locale: string = 'en';

  constructor(
    protected readonly baseUrl: string,
    protected readonly fileHandling: FileHandlingBackend,
  ) {
  }

  abstract fileUploadAbsolute(options: FileUploadAbsoluteOptions): Promise<string>;

  setLocale(locale: string): void {
    this.locale = locale;
  }

  async request(options: RequestOptions): Promise<Response> {
    const url = `${this.baseUrl}/${options.endpoint}`;
    return await this.doRequest(url, options.method, options.headers ?? { }, options.body ?? null, options.throwOnFailure ?? true);
  }

  async fileUpload<T>(options: FileUploadOptions): Promise<T> {
    const url = `${this.baseUrl}/${options.endpoint}`;
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${options.token}`,
      'Accept-Language': this.locale,
    }

    const body = await this.fileUploadAbsolute({
      url,
      headers,
      method: options.method,
      field: options.field,
      file: options.file,
      additionalFields: options.additionalFields ?? { },
    });

    return JSON.parse(body);
  }

  protected async doRequest(
    url: string,
    method: 'GET' | 'POST' | 'PATCH',
    headers: Record<string, string>,
    body: Record<string, any> | FormData | null,
    throwOnFailure: boolean,
  ) {
    if (!headers['Accept']) {
      headers['Accept'] = 'application/json';
    }
    if (!headers['Accept-Language']) {
      headers['Accept-Language'] = this.locale;
    }

    const init: RequestInit = {
      headers: headers,
      method: method,
    }

    if (body) {
      if (body instanceof FormData) {
        init.body = body;
      } else {
        init.body = JSON.stringify(body);
        headers['Content-Type'] = 'application/json';
      }
    }

    const response = await fetch(url, init)

    if (!response.ok && throwOnFailure) {
      let responseBody = null;
      try {
        responseBody = await response.json()
      } catch (e: any) {
        console.log(e)
        // Ignore
      }

      const message = responseBody?.message ?? responseBody?.error ?? `Failed to fetch ${url}`;
      throw new ApiError(
          response.status,
          message,
          responseBody,
      );
    }

    return response;
  }
}
