import {Directory, Filesystem} from "@capacitor/filesystem";
import {v4 as uuidv4} from "uuid";
import {TemporaryFileHandler} from "./index";
import {base64Encode} from "./helpers";

export class GenericTemporaryFileHandler implements TemporaryFileHandler {
  public constructor(
    private readonly baseDirectory: string,
  ) {

  }

  async writeToDisk(fileName: string, data: string | Blob): Promise<string> {
    await Filesystem.requestPermissions();

    const uuid = uuidv4();
    await Filesystem.mkdir({
      path: `${this.baseDirectory}/${uuid}`,
      recursive: true,
      directory: Directory.Data,
    })

    const base64Data = typeof data === 'string' ? data : await base64Encode(data);
    const result = await Filesystem.writeFile({
      path: `${this.baseDirectory}/${uuid}/${fileName}`,
      data: base64Data,
      directory: Directory.Data,
    })

    return result.uri;
  }

  async clearStorage(): Promise<void> {
    await Filesystem.rmdir({
      path: this.baseDirectory,
      directory: Directory.Data,
      recursive: true,
    })
  }
}
