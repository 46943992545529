import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {IonApp, IonRouterOutlet, setupIonicReact} from '@ionic/react';
import {IonReactHashRouter} from '@ionic/react-router';
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {APIProvider} from "@vis.gl/react-google-maps";
import {environment} from "./environment";
import {ConditionsReportEditingProvider, getConditionsReportRoutes,} from "./data/ConditionsReportEditing";
import {LoginStateContextProvider} from "./data/Login";
import {NewsProvider} from "./data/News";
import {LanguageStateContextProvider} from "./i18n/i18n";
import {implementationChooser} from "./data/Helpers";

setupIonicReact();

const classes = implementationChooser<string[]>({
    web: () => [ 'web' ],
    ios: () => [ 'native', 'ios' ],
    android: () => [ 'native', 'android' ],
})
if (!environment.isProduction) {
    classes.push('environment-dev');
}

const App: React.FC = () => {
    return <APIProvider apiKey={environment.googleMapsApiKey}>
        <LanguageStateContextProvider>
            <IonApp className={classes.join(' ')}>
                <IonReactHashRouter basename={environment.baseUrl}>
                    <LoginStateContextProvider>
                        <NewsProvider>
                            <IonRouterOutlet animated={true}>
                                <ConditionsReportEditingProvider>
                                    {getConditionsReportRoutes()}
                                </ConditionsReportEditingProvider>
                                <Route exact path="/home">
                                    <Home/>
                                </Route>
                                <Route exact path="/">
                                    <Redirect to="/home"/>
                                </Route>
                            </IonRouterOutlet>
                        </NewsProvider>
                    </LoginStateContextProvider>
                </IonReactHashRouter>
            </IonApp>
        </LanguageStateContextProvider>
    </APIProvider>
};

export default App;
