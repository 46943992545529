import {
    IonButton,
    IonButtons,
    IonIcon,
    IonItem,
    IonLabel, IonList,
    IonProgressBar,
    IonReorder,
    IonReorderGroup,
    IonText,
    ItemReorderEventDetail,
} from "@ionic/react";
import React from "react";
import {
  camera,
  imageOutline,
  repeatOutline,
  star,
  trashBin
} from "ionicons/icons";
import {Alert} from "@mui/material";
import {imageHandling, TemporaryImage} from "../data/images";
import {TemporaryImagePreview} from "./TemporaryFilepreview";
import {useI18n} from "../i18n/i18n";

interface ImagePickerProps {
    maxWidthPx: number,
    maxHeightPx: number,
    maxSizeMb: number,
    images: TemporaryImage[],
    onChange: (images: TemporaryImage[]) => void,
}

export const ImagePicker: React.FunctionComponent<ImagePickerProps> = (props) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [ errors, setErrors ] = React.useState<string[]>([]);
    const [ canChooseFromCamera, setCanChooseFromCamera ] = React.useState<boolean>(false);
    const [ canChooseFromGallery, setCanChooseFromGallery ] = React.useState<boolean>(false);
    const primaryIndex = 0;

    React.useEffect(() => {
        imageHandling.canLoadPhotoFromCamera().then(setCanChooseFromCamera);
        imageHandling.canLoadPhotoFromGallery().then(setCanChooseFromGallery);
    }, []);

    const addImages = (images: TemporaryImage[]) => props.onChange([...props.images, ...images]);
    const removeImage = (index: number) => props.onChange(props.images.filter((_, i) => i !== index));
    const { label } = useI18n();

    const addPhotoFromGallery = async () => {
        setErrors([ ]);
        setLoading(true);

        try {
            const images = await imageHandling.loadPhotosFromGallery({
                width: props.maxWidthPx,
                height: props.maxHeightPx,
            });

            const validImages: TemporaryImage[] = [ ];
            for (const image of images) {
                if (image.sizeMb > props.maxSizeMb) {
                    setErrors([...errors, label('image_picker.error.too_large', { fileName: image.fileName, maxSizeMb: `${props.maxSizeMb}` })]);
                    continue;
                }
                validImages.push(image);
            }

            addImages(validImages)
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
        }

        setLoading(false);
    }

    const addPhotoFromCamera = async () => {
        setErrors([ ]);
        setLoading(true);
        try {
            const photo = await imageHandling.loadPhotoFromCamera({ width: props.maxWidthPx, height: props.maxHeightPx })
            if (photo !== null) {
                addImages([ photo ])
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
        }
        setLoading(false);
    }

    const rotateImageInPicker = async (index: number) => {
        setErrors([ ]);

        const image = await imageHandling.rotateImage(props.images[index]);
        const newImages = [...props.images]
        newImages[index] = image

        props.onChange(newImages)
        setLoading(false)
    }

    function handleReorder(event: CustomEvent<ItemReorderEventDetail>) {
        setErrors([ ]);
        const newImages = [...props.images];
        const item = newImages.splice(event.detail.from, 1)[0];
        newImages.splice(event.detail.to, 0, item);

        props.onChange(newImages);
        event.detail.complete();
    }

        return <React.Fragment>
        <IonText><h5>{label('image_picker.title')}</h5></IonText>

        <IonItem>
            <IonLabel>{label('image_picker.select')}*</IonLabel>
            <IonButtons>
                {canChooseFromCamera && <IonButton onClick={addPhotoFromCamera}>
                    <IonIcon icon={camera}/>
                </IonButton>}
                {canChooseFromGallery && <IonButton onClick={addPhotoFromGallery}>
                    <IonIcon icon={imageOutline}/>
                </IonButton>}
            </IonButtons>
        </IonItem>

        {loading && <IonProgressBar type="indeterminate"/>}
            {props.images.length > 0 && <Alert className="ion-margin" severity="info">
                {label('image_picker.info.main_image')}
            </Alert>}

            <IonList>
                <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>
                    {props.images.map((image, index) => <IonItem key={image.nativePath} style={{ flexWrap: 'wrap' }}>
                        <TemporaryImagePreview image={image} bounds={{ width: 300, height: 300 }}>
                            {primaryIndex === index && <IonIcon icon={star} color='warning' size="large" style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                filter: 'drop-shadow( 0 0 10px rgba(0, 0, 0, .5))'
                            }}/>}
                        </TemporaryImagePreview>
                        <div style={{ flexGrow: '1' }} />
                        <IonButtons>
                            <IonButton color="secondary" onClick={() => rotateImageInPicker(index)}>
                                <IonIcon icon={repeatOutline} size={"large"}/>
                            </IonButton>
                            <IonButton color="danger" onClick={() => removeImage(index)}>
                                <IonIcon icon={trashBin} size={"large"}/>
                            </IonButton>
                        </IonButtons>
                        <IonReorder slot="end"></IonReorder>
                    </IonItem>)}
                </IonReorderGroup>
            </IonList>
    </React.Fragment>
}
