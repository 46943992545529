import {ConditionsReport} from "../../data/ConditionsReport";
import {TemporaryVideo} from "../../data/videos";
import {StepData} from "../../data/ConditionsReportEditing";
import {TemporaryImage} from "../../data/images";

export interface ConditionsReportWithTemporaryMedia {
    conditionsReport: ConditionsReport,
    temporaryImages: TemporaryImage[],
    temporaryVideos: TemporaryVideo[],
}

/**
 * Converts the step data to a conditions report.
 */
export function convertStepDataToCr(data: StepData): ConditionsReportWithTemporaryMedia {
    const cr: ConditionsReport = {
        title: data.step4.title,
        activity_date: data.step1.date,
        activity: data.step1.kind,
        media: [],
        video: null,
        exposition: data.step1.exposition,
        avalanche_warning_level: data.step3.avalancheWarningLevel,
        altitude_lower: data.step1.altitude.lower,
        altitude_upper: data.step1.altitude.upper,
        spot_id: data.step1.location.uuid,
        snowquality: {
            rating: data.step2.snowQualityRating! * 2,
            description: data.step2.snowQualityDescription,
            attributes: {
                old_snow: data.step2.snowQualityOldSnow,
                breakable_crust: data.step2.snowQualityBreakableCrust,
                slush: data.step2.snowQualitySlush,
                firn: data.step2.snowQualityFirn,
                wet_snow: data.step2.snowQualityWetSnow,
                powder: data.step2.snowQualityPowder,
                summer_snow: data.step2.snowQualitySummerSnow,
                jelly_snow: data.step2.snowQualityJellySnow,
                wind_drifted: data.step2.snowQualityWindDrifted,
            }
        },
        snowcondition: {
            rating: data.step2.snowConditionRating! * 2,
            description: data.step2.snowConditionDescription,
            attributes: {
                descent_to_valley: data.step2.snowConditionDescentToValley,
                descent_to_height: data.step2.snowConditionDescentToHeight,
                descent_through_forest: data.step2.snowConditionDescentThroughForest,
                blown_backs: data.step2.snowConditionBlownBacks,
                covered_shoes: data.step2.snowConditionCoveredShoes,
                variations_of_snow_cover: data.step2.snowConditionVariationsOfSnowCover,
                stone_contact_avoidable: data.step2.snowConditionStoneContactAvoidable,
                fresh_snow: data.step2.snowConditionFreshSnow!,
            }
        },
        risk: {
            rating: 0,
            description: data.step3.riskDescription,
            attributes: {
                no_warning_signs: data.step3.riskNoWarningSigns,
                abort: data.step3.riskAbort,
                cracking: data.step3.riskCracking,
                fresh_avalanches: data.step3.riskFreshAvalanches,
                rockfall: data.step3.riskRockfall,
                crevices: data.step3.riskCrevices,
                diurnal_warming: data.step3.riskDiurnalWarming,
                much_fresh_snow: data.step3.riskMuchFreshSnow,
                much_water: data.step3.riskMuchWater,
                hissing_noise: data.step3.riskHissingNoise,
                old_snow_problem: data.step3.riskOldSnowProblem,
                wind_direction: data.step3.riskWindDirection,
                wind_intensity: data.step3.riskWindIntensity!,
            }
        },
        overall: {
            rating: data.step4.overallRating * 2,
            description: data.step4.overallDescription,
            attributes: {
                trend: data.step4.overallTrend!,
                index_of_first_image: 0,
            }
        }
    };

    if (data.step2.snowQualityWindDrifted) {
        cr.snowquality.attributes.wind_drifted_attrs = {
            exposition: data.step2.snowQualityWindDriftedExposition,
            altitude_upper: data.step2.snowQualityWindDriftedAltitude?.upper ?? 0,
            altitude_lower: data.step2.snowQualityWindDriftedAltitude?.lower ?? 0,
            intensity: data.step2.snowQualityWindDriftedIntensity,
            description: data.step2.snowQualityWindDriftedDescription
        }
    }

    if (data.step3.riskFreshAvalanches) {
        cr.risk.attributes.fresh_avalanches_attrs = {
            exposition: data.step3.riskFreshAvalancheExposition!,
            altitude: data.step3.riskFreshAvalancheAltitude!,
            size: data.step3.riskFreshAvalancheSize!,
            type: data.step3.riskFreshAvalancheType!,
            description: data.step3.riskFreshAvalancheDescription
        }
    }

    return {
        conditionsReport: cr,
        temporaryImages: data.step4.images,
        temporaryVideos: data.step4.videos,
    }
}