import {PickedFile} from "@capawesome/capacitor-file-picker";
import {Capacitor} from "@capacitor/core";
import {VideoEditor} from "@whiteguru/capacitor-plugin-video-editor";
import {GenericVideoHandlingBackend} from "./GenericVideoHandlingBackend";

export class IosVideoHandlingBackend extends GenericVideoHandlingBackend {
  async canLoadVideosFromCamera(): Promise<boolean> {
    return true;
  }
  async canLoadVideosFromGallery(): Promise<boolean> {
    return true;
  }

  // Ios: The file path returned by pickVideos can be accessed directly.
  protected async extractPathsFromGalleryFile(file: PickedFile): Promise<{ pathOnDisk: string, publicPath: string }> {
    return {
      pathOnDisk: file.path!,
      publicPath: Capacitor.convertFileSrc(file.path!),
    };
  }

  protected async createVideoThumbnail(path: string): Promise<string> {
    const result = await VideoEditor.thumbnail({
      path: path,
      width: 320,
      height: 240,
    })

    return result.file.path;
  }
}