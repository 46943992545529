import React from "react";
import {Exposition} from "../data/ConditionsReport";
import {ExpositionWindRose} from "./ExpositionWindRose";

interface ExpositionSelectorSingleProps {
    exposition: Exposition | null,
    onChange: (exposition: Exposition) => void,
}

export const ExpositionSelectorSingle: React.FunctionComponent<ExpositionSelectorSingleProps> = (props) => {
    const expositions = props.exposition === null ? [] : [props.exposition]
    return <ExpositionWindRose active={expositions} onClick={direction => props.onChange(direction)}/>
}

interface ExpositionSelectorMultipleProps {
    expositions: Exposition[],
    onChange: (expositions: Exposition[]) => void,
}

export const ExpositionSelectorMultiple: React.FunctionComponent<ExpositionSelectorMultipleProps> = (props) => {
    const onClick = (exposition: Exposition) => {
        if (props.expositions.includes(exposition)) {
            props.onChange(props.expositions.filter(e => e !== exposition))
        } else {
            props.onChange([...props.expositions, exposition])
        }
    }

    return <ExpositionWindRose active={props.expositions} onClick={onClick}/>
}