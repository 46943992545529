import {NewsItem} from "../data/News";
import React from "react";
import {Alert, AlertColor} from "@mui/material";
import {IonButton} from "@ionic/react";

interface SingleNewsProps {
    newsItem: NewsItem,
}

export const SingleNews: React.FunctionComponent<SingleNewsProps> = props => {
    let severity: AlertColor = 'info';
    if (props.newsItem.severity === 'warning') {
        severity = 'warning';
    } else if (props.newsItem.severity === 'error') {
        severity = 'error';
    }

    return <Alert severity={severity}>
        <h2>{props.newsItem.title}</h2>
        <p>{props.newsItem.text}</p>
        {props.newsItem.actions.map(action => (
            <IonButton key={action.url} href={action.url}>{action.text}</IonButton>
        ))}
    </Alert>
}

export const MultipleNews: React.FunctionComponent<{ news: NewsItem[] }> = props => {
    if (props.news.length === 0) {
        return null;
    }

    return <React.Fragment>
        {props.news.map(newsItem => <SingleNews key={newsItem.id} newsItem={newsItem}/>)}
    </React.Fragment>
}